import React from "react";

// reactstrap components
import {Container, Table,} from "reactstrap";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";

function CartPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <Container className="text-center">
            <Table responsive>
              <table class="table-shopping table">
                <thead>
                  <tr>
                    <th class="text-center">
                    </th>
                    <th>Product</th>
                    <th>Color</th>
                    <th>Size</th>
                    <th class="text-right">Price</th>
                    <th class="text-right">Qty</th>
                    <th class="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="img-container">
                        <img alt="..." src="/now-ui-kit-pro-react/static/media/saint-laurent.d601f458.jpg" />
                      </div>
                    </td>
                    <td class="td-name">
                      <a href="#pablo">Suede Biker Jacket</a>
                      <br />
                        <small>by Saint Laurent</small>
                    </td>
                    <td>Black</td>
                    <td>M</td>
                    <td class="td-number">
                      <small>€</small>3,390</td>
                    <td class="td-number">1 <div role="group" class="btn-group">
                      <button class="btn btn-info btn-sm">
                        <i class="now-ui-icons ui-1_simple-delete">
                        </i>
                      </button>
                      <button class="btn btn-info btn-sm">
                        <i class="now-ui-icons ui-1_simple-add">
                        </i>
                      </button>
                    </div>
                    </td>
                    <td class="td-number">
                      <small>€</small>549</td>
                    <td class="td-actions">
                      <button type="button" data-placement="left" id="tooltip109218971" class="btn btn-neutral">
                        <i class="now-ui-icons ui-1_simple-remove">
                        </i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="img-container">
                        <img alt="..." src="/now-ui-kit-pro-react/static/media/balmain.7caf5aff.jpg" />
                      </div>
                    </td>
                    <td class="td-name">
                      <a href="#pablo">Jersey T-Shirt</a>
                      <br/>
                        <small>by Balmain</small>
                    </td>
                    <td>Black</td>
                    <td>M</td>
                    <td class="td-number">
                      <small>€</small>499</td>
                    <td class="td-number">2 <div role="group" class="btn-group">
                      <button class="btn btn-info btn-sm">
                        <i class="now-ui-icons ui-1_simple-delete">
                        </i>
                      </button>
                      <button class="btn btn-info btn-sm">
                        <i class="now-ui-icons ui-1_simple-add">
                        </i>
                      </button>
                    </div>
                    </td>
                    <td class="td-number">
                      <small>€</small>998</td>
                    <td class="td-actions">
                      <button type="button" data-placement="left" id="tooltip230976474" class="btn btn-neutral">
                        <i class="now-ui-icons ui-1_simple-remove">
                        </i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="img-container">
                        <img alt="..." src="/now-ui-kit-pro-react/static/media/prada.893b4dac.jpg" />
                      </div>
                    </td>
                    <td class="td-name">
                      <a href="#pablo">Slim-Fit Swim Short</a>
                      <br />
                        <small>by Prada</small>
                    </td>
                    <td>Red</td>
                    <td>M</td>
                    <td class="td-number">
                      <small>€</small>200</td>
                    <td class="td-number">1 <div role="group" class="btn-group">
                      <button class="btn btn-info btn-sm">
                        <i class="now-ui-icons ui-1_simple-delete">
                        </i>
                      </button>
                      <button class="btn btn-info btn-sm">
                        <i class="now-ui-icons ui-1_simple-add">
                        </i>
                      </button>
                    </div>
                    </td>
                    <td class="td-number">
                      <small>€</small>799</td>
                    <td class="td-actions">
                      <button type="button" data-placement="left" id="tooltip11104356" class="btn btn-neutral">
                        <i class="now-ui-icons ui-1_simple-remove">
                        </i>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                    </td>
                    <td class="td-total">Total</td>
                    <td class="td-price">
                      <small>€</small>2,346</td>
                    <td class="text-right" colspan="3">
                      <button type="button" class="btn-round btn btn-info">Complete Purchase <i class="now-ui-icons arrows-1_minimal-right">
                      </i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Table>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default CartPage;
