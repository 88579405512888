import React from "react";
import {Link} from "react-router-dom";
// reactstrap components
import {Button, Col, Container, Row,} from "reactstrap";

// core components

function MembershipPlans() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  React.useEffect(() => {
    let userInfo = localStorage.getItem('user-info');
    if (userInfo != null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session != null) {
        setLoggedIn(true);
      }
    };
  }, []);
  return (
    <>
      <div
        className="section"
        data-background-color="black"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">Our Membership Plans</h2>
            </Col>
          </Row>
          <br /><br /><br />
          <Row>
            <Col>
              <div class="table-responsive">
                <table class="table-pricing table">
                  <thead class="text-primary text-white">
                    <tr>
                      <th>
                        {/* <img alt="..." class="background" src="https://demos.creative-tim.com/argon-design-system-pro-react/assets/img/ill/bg4-1.svg" /> */}
                      </th>
                      <th class="text-center">
                        <h6 class="card-title"><b>AASHIRBAD</b></h6>
                      </th>
                      <th class="text-center">
                        <h6 class="card-title"><b>HOI HULLOR</b></h6>
                      </th>
                      <th class="text-center">
                        <h6 class="card-title"><b>UTSAV</b></h6>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="bg-primary">
                      <td class="text-white" colSpan={4}><b>Membership Contribution (per Person)</b></td>
                    </tr>
                    <tr class="text-white">
                      <td>
                     
                          <b>Contribution</b> (<b>UTSAV</b> contribution will be lower for family of three or more members)
                      </td>
                      <td class="text-center">
                        <b>&#x20B9;1250</b>
                      </td>
                      <td class="text-center">
                        <b>&#x20B9;2000</b>
                      </td>
                      <td class="text-center">
                          <b>&#x20B9;2400</b>
                      </td>
                    </tr>
                    {/* <tr class="bg-secondary">
                      <td class="text-white" colSpan={4}>
                        <b>Three or more</b> can get a reduced contribution on <b>"UTSAV Membership plan"</b>

                      </td>
                    </tr> */}
                    {/* <tr class="text-white">
                      <td>
                        Platform fee<i class="ni ni-compass-04 ml-1"></i>
                      </td>
                      <td class="text-center">3%</td>
                      <td class="text-center">3%</td>
                      <td class="text-center">
                        2%
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>Payment processing</td>
                      <td class="text-center">5.5% + 5c</td>
                      <td class="text-center">6.2% + 5c</td>
                      <td class="text-center">
                        <a href="#pablo">Contact us</a>
                      </td>
                    </tr> */}
                    <tr class="bg-primary">
                      <td class="text-white"><b>Features</b></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr class="text-white">
                      <td>Member dedicated queue for Anjali, Bhog & Maa Boron (*)</td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Member dedicated queue for Maa Boron (*)</td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr>
                      <td>Member priority queue for Bhog (*)</td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr> */}
                    <tr class="text-white">
                      <td>Parking (on availability)</td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr class="text-white">
                      <td>Bhog - Saptami</td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr class="text-white">
                      <td>Bhog - Ashtami & Nabami</td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr class="text-white">
                      <td>Dandiya Participation</td>
                      <td class="text-center">
                        <span></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr class="text-white">
                      <td>Cultural Program - External</td>
                      <td class="text-center">
                        <span></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr class="text-white">
                      <td>Participation in in-house cultural program</td>
                      <td class="text-center">
                        <span></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr class="text-white">
                      <td>Gala Dinner - Bijoya Dashami (**)</td>
                      <td class="text-center">
                        <span></span>
                      </td>
                      <td class="text-center">
                        <span></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr class="text-white">
                      <td>Bhog in Laxmi, Kaali & Saraswati Puja (**)</td>
                      <td class="text-center">
                        <span></span>
                      </td>
                      <td class="text-center">
                        <span></span>
                      </td>
                      <td class="text-center">
                        <span class="badge-circle badge badge-success"><i></i></span>
                      </td>
                    </tr>
                    <tr class="bg-secondary">
                      <td class="text-white" colSpan={4}>* Members will not have any preference over non-members except that queue will be dedicated which may result into lesser waiting time</td>
                    </tr>
                    {/* <tr class="bg-secondary">
                      <td class="text-white" colSpan={4}>** Parking is subject to availability</td>
                    </tr> */}
                    <tr class="bg-secondary">
                      <td class="text-white" colSpan={4}>** Based on surplus fund availability</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
          <div className="col text-center">

            {loggedIn === false &&
              <Button
                className="btn-round btn-white"
                color="danger"
                to="/login"
                size="lg"
                tag={Link}
              >
                <b>Create Account to become member</b>
              </Button>
            }

            {/* {loggedIn && !memberAccount &&
              <Button
                className="btn-round btn-white"
                color="danger"
                to="/membership"
                size="lg"
                tag={Link}
              >
                Become Member
              </Button>
            }

            {loggedIn && memberAccount &&
              <Button
                className="btn-round btn-white"
                color="danger"
                to="/profile"
                size="lg"
                tag={Link}
              >
                View Your Membership
              </Button>
            } */}

          </div>
        </Container>
      </div>
    </>
  );
}

export default MembershipPlans
  ;
