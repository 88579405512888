import React from "react";

// reactstrap components
// import {
// } from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page
import Carousel from "./index-sections/Gallary.js";
import AboutUs from "./index-sections/AboutUs.js";
import WhyMore from "./index-sections/WhyMore.js";
import MembershipPlans from "./index-sections/MembershipPlans.js";
import ContactUs from "./index-sections/ContactUs.js";
import Corporate from "./index-sections/Corporate.js";
import Schedule from "./index-sections/Schedule.js";


function Index() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="main">
          {/*<Schedule />*/}
          <AboutUs />
          <Carousel />
          {/*<Corporate />*/}
          <WhyMore />
          <MembershipPlans />
          <ContactUs />
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default Index;
