import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";

// core components

function WhyMore() {
  return (
    <>
      <div
        className="section"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">What more?</h2>
            </Col>
            <Col className="text-justify" lg="8" md="12">
              <h5 className="description"><b>
                The popular saying in Bengali “Baro Mase Tero Parban” literally means thirteen festivals in twelve months. Bengal has always been a land of festivals as these have not only strengthened the very social bonding but have also let Art and Culture thrive through centuries. Though away from our roots, in our present city of Bangalore, we have always striven hard to keep with the tradition.
                <br /><br />
                SORRBA provides platform to all the members to participate in many cultural events to showcase their talent infront of large audience. This is the platform where a member's child can even particiapte in drama and art competition. By organizing large events, SORRBA enhances earning opportunity of many un-organized sector(s) arround the vicinity.
                <br /><br />
                SORRBA been managed with highest level of ethics and transparency including compliance to Income-Tax filing and GST regulations. Over the years, we have participated in verious activities benefiting the under previledged and the needy sections of our society.
              </b>
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WhyMore;
