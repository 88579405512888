/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function TransparentFooter() {
  return (
    <footer className="footer">
      <Container>
       <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Designed and Developed by{" "}
          <a
            href="https://www.linkedin.com/in/rajdipg/"
            target="_blank"
          >
            Rajdip Ghosh
          </a>
          {/* {" "}(Executive Committee member of SORRBA) */}
        </div>
      </Container>
    </footer>
  );
}

export default TransparentFooter;
