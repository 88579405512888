import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    InputGroup,
    Row,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import {APIRequest} from "utils/ApiRequest";
import {toast} from 'react-toastify';

function ForgotPasswordPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const history = useNavigate();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let userInfo = localStorage.getItem('user-info');
    if (userInfo != null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session != null) {
        history("/index");
      }
    }

    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.trim());
  };

  function handleForgotPassword() {
    if ((email === null || email === "")) {
      toast.error('Email is Mandatory!');
    }
    else if (!isValidEmail(email)) {
      toast.error('Email is invalid!');
    } else {
      APIRequest(
        null,
        "/forgotPasswd",
        {
          email
        },
        (res) => {
          toast.success("An email with temp code has been sent to your registered email id, please check your mail.")
          history("/changePasswd");
        }
      );
    }
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <>
      <IndexNavbar />
      <div className="page-header clear-filter" filter-color="orange">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg22.png") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <div className="logo-container">
                      <img
                        alt="..."
                        src={require("assets/img/now-logo.png")}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="email"
                        type="text"
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        onChange={handleEmailChange}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="danger"
                      onClick={handleForgotPassword}
                      size="lg"
                    >
                      <b>Forgot Password</b>
                    </Button>

                    <Row>
                      <Col md="6">
                        <Button
                          className="btn-link text-left"
                          color="success"
                          to="/signup"
                          size="lg"
                          tag={Link}
                        >
                          <b>Back to Login</b>
                        </Button>
                      </Col>

                    </Row>

                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default ForgotPasswordPage;
