import React from "react";

// reactstrap components
import {Button, Card, CardBody, CardImg, CardTitle, Col, Container, Row,} from "reactstrap";

// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader";

function ToshaniPage() {

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function handleToshaniDownload(url, filename) {
    console.log("File Download Triggers: " + url);
    //fileDownload(url, filename);
    fetch(require(url)).then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = filename;
          alink.click();
      })
  })
  }

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <Container>
            <h3 className="title">Toshani - SORRBA's Annual Magazine</h3>

            <Row>
              <Col md="3">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  {/* <CardImg
                    alt="..."
                    src={require("assets/img/15y.png")}
                    top
                  ></CardImg> */}
                  
                  <CardBody>
                    <CardTitle tag="h4">Toshani 2020</CardTitle>
                    <Button className="btn-round" color="primary" onClick={() => handleToshaniDownload("assets/toshani2020.pdf", "Toshani-2020.pdf")}>Download</Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardImg
                    alt="..."
                    src={require("assets/img/toshani2019.jpg")}
                    top
                  ></CardImg>
                  
                  <CardBody>
                    <CardTitle tag="h4">Toshani 2019</CardTitle>
                    <Button className="btn-round" color="primary" onClick={() => handleToshaniDownload("assets/toshani2019.pdf", "Toshani-2020.pdf")}>Download</Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardImg
                    alt="..."
                    src={require("assets/img/toshani2018.jpg")}
                    top
                  ></CardImg>
                  
                  <CardBody>
                    <CardTitle tag="h4">Toshani 2018</CardTitle>
                    <Button className="btn-round" color="primary" onClick={() => handleToshaniDownload("assets/toshani2018.pdf", "Toshani-2018.pdf")}>Download</Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardImg
                    alt="..."
                    src={require("assets/img/toshani2017.jpg")}
                    top
                  ></CardImg>
                  
                  <CardBody>
                    <CardTitle tag="h4">Toshani 2017</CardTitle>
                    <Button className="btn-round" color="primary" onClick={() => handleToshaniDownload("assets/toshani2017.pdf", "Toshani-2017.pdf")}>Download</Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>


          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ToshaniPage;
