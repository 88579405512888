import React, {useState} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";

// core components
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {APIRequest} from "utils/ApiRequest";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import LandingPageHeader from "components/Headers/LandingPageHeader";

function ProfilePage() {
  const [modalLarge, setModalLarge] = React.useState(false);
  const [addMoneyModal, setAddMoneyModal] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState(null);
  const [orders, setOrders] = React.useState(null);
  const history = useNavigate();
  const [token, setToken] = React.useState(null);
  const [plainTabs, setPlainTabs] = React.useState("2");
  const [filteredOrder, setFilteredOrder] = React.useState(null);
  const [modalLargeN, setModalLargeN] = React.useState(false);
  const [offlineTxnId, setOfflineTxnId] = useState(null);
  const [offlineBankName, setOfflineBankName] = useState(null);
  const [orderRcptId, setOrderRcptId] = useState(null);
  const [offlineTxnIdFocus, setOfflineTxnIdFocus] = React.useState(false);
  const [orderTotal, setOrderTotal] = useState(null);
  const [offlineBankNameFocus, setOfflineBankNameFocus] = React.useState(false);
  const [memberAccount, setMemberAccount] = React.useState(false);
  const [amountFocus, setAmountFocus] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const [selectedPrds, setSelectedPrds] = useState({});

  React.useEffect(() => {
    let userInfo = localStorage.getItem('user-info');
    if (userInfo !== null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session != null) {
        setToken(userInfo.session);
        loadUserDetails(userInfo.session);
        loadOrderDetails(userInfo.session);
        setMemberAccount(userInfo.member);
      } else {
        history("/login")
      }
    } else {
      history("/login")
    }
    document.body.classList.add("profile-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("profile-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function loadUserDetails(token) {
    APIRequest(
      token,
      "/getUserProfile",
      {},
      (res) => {
        setUserProfile(res.data);
      }
    );
  }

  function loadOrderDetails(token) {
    APIRequest(
      token,
      "/getOrders",
      {},
      (res) => {
        setOrders(res.data);

      }
    );
  }

  const handleOrderFetch = (orderRcptId) => {
    if (orders === null) return;
    let filteredOrder = orders.filter((order) => order.orderRcptId === orderRcptId);
    console.log(filteredOrder);
    setFilteredOrder(filteredOrder);
    setModalLarge(true);
  }

  const handleSendVerification = () => {
    APIRequest(
      token,
      "/sendVerificationEmail",
      {},
      (res) => {
        toast.success("We have sent verification link, please check your Mail!");
      }
    );
  }

  const handleOfflineUpdDetails = (e) => {
    if (offlineTxnId === undefined || offlineTxnId === null || offlineTxnId === "") {
      toast.error("Please add the transaction id to proceed");
      return;
    }
    APIRequest(
      token,
      "/handleOfflineOrder",
      { isOffline: true, offlineTxnId, offlineBankName, orderRcptId },
      (res) => {
        toast.success('Your contribution deatils are captured and sent for verfication, will sent you an email on once verification completes!');
      },
    );
    e.preventDefault();
  }

  const handleAddMoney = () => {
    setAddMoneyModal(true);
  }

  const handleAddMoneyProceed = (e) => {
    if (amount === null || amount === undefined || amount.trim === "" || amount < 500) {
      toast.error("500 is the minimum amount that can be added to wallet!");
      return;
    }
    let prdDetails = {};
    prdDetails["qty"] = 1;
    prdDetails["price"] = amount;
    prdDetails["code"] = "WLP";
    selectedPrds[85] = prdDetails;
    APIRequest(
      token,
      "/addToCart",
      JSON.parse('{"productQtys":' + JSON.stringify(selectedPrds) + '}'),
      (res) => {
        history("/walletSummary");
      },
      (err) => {
        try {
          return toast.error(err.data.desc);
        } catch (error) {
          toast.error('Seems some internal issue with your network, please check your network!');
        }
      }
    );
  }

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  }

  const addMoreMoney = (a, val) => {
    if (a === null || a === undefined || a.trim === "") a = 0;
    a = a + val;
    setAmount(a);
  }

  async function loadOfflinePay(order) {
    setOrderRcptId(order.orderRcptId);
    setOrderTotal(order.orderTotal);
    setModalLargeN(true);
  }


  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <Container>
            <h3 className="title">Your Profile and Contribution</h3>

            <Card className="card-nav-tabs card-plain">
              <CardHeader className="card-header-danger">
                <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <Nav data-tabs="tabs" tabs>
                      <NavItem>
                        <NavLink
                          className={plainTabs === "1" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            setPlainTabs("1");
                          }}
                        >
                          Profile
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={plainTabs === "2" ? "active" : ""}
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            setPlainTabs("2");
                          }}
                        >
                          Contribution
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <TabContent
                  className="text-center"
                  activeTab={"plainTabs" + plainTabs}
                >
                  <TabPane tabId="plainTabs1">
                    <Table responsive>

                      {userProfile !== null &&
                        <tbody>
                          <tr class="text-left">
                            <td><b>Name</b></td>
                            <td><b>{userProfile.name}</b></td>
                            <td></td>
                          </tr>
                          <tr class="text-left">
                            <td><b>Email ID</b></td>
                            <td><b>{userProfile.email}</b></td>
                            <td><b>{userProfile.emailVerified ? "Email Verified" : <Button className="btn-link" onClick={handleSendVerification}>Send Email Verification Link</Button>}</b></td>
                          </tr>
                          <tr class="text-left">
                            <td><b>Mobile No</b></td>
                            <td><b>{userProfile.mobile}</b></td>
                            <td></td>
                          </tr>
                        </tbody>
                      }

                    </Table>
                  </TabPane>
                  <TabPane tabId="plainTabs2">
                    {orders !== null && orders.length > 0 &&
                      <Table responsive>
                        <thead>
                          <tr className="bg-primary text-white">
                            <td><b>Payment Date</b></td>
                            <td><b>Contribution ID</b></td>
                            <td><b>Year of Contribution</b></td>
                            <td><b>Amount</b></td>
                            <td><b>Status</b></td>
                            <td><b>Link</b></td>
                          </tr>
                        </thead>
                        <tbody>
                          {orders.map((order) => (
                            <tr>
                              <td>{order.paymentDate}</td>
                              <td>{order.orderRcptId}</td>
                              <td>{order.fiscalYear}</td>
                              <td>{order.orderTotal}</td>
                              <td><b>{order.status}</b></td>

                              {order.status !== null && (order.status === "Success" || order.status === "Pending Approval") &&
                                <td>
                                  <Button className="btn-link" color="primary" onClick={() => handleOrderFetch(order.orderRcptId)}><b>Click for Details</b></Button>
                                </td>
                              }
                              {order.status !== null && order.status === "Pending Payment" && order.paymentType === "Offline" &&
                                <td>
                                  <Button className="btn-link" color="danger" onClick={() => loadOfflinePay(order)}><b>Retry Payment</b></Button>
                                  <Button className="btn-link" color="primary" onClick={() => handleOrderFetch(order.orderRcptId)}><b>Click for Details</b></Button>
                                </td>
                              }
                              {order.status !== null && order.paymentType === "Online" && order.status !== "Success" &&
                                <td>
                                  <Button className="btn-link" color="danger" to="/membership" tag={Link}><b>Start Again</b></Button>
                                  <Button className="btn-link" color="primary" onClick={() => handleOrderFetch(order.orderRcptId)}><b>Click for Details</b></Button>
                                </td>
                              }
                              {order.status !== null && order.paymentType === "Offline" && order.status === "Failed" &&
                                <td>
                                  <Button className="btn-link" color="danger" to="/membership" tag={Link}><b>Start Again</b></Button>
                                  <Button className="btn-link" color="primary" onClick={() => handleOrderFetch(order.orderRcptId)}><b>Click for Details</b></Button>
                                </td>
                              }
                            </tr>
                          ))
                          }
                        </tbody>
                      </Table>
                    }
                    {/* {(orders === null || orders.length <= 0) &&
                      <Row>
                        <Col>
                          <Button
                            className="btn-round"
                            color="primary"
                            size="lg"
                            to="/dmPasss"
                            tag={Link}
                          >
                            <i className="now-ui-icons arrows-1_share-66 mr-1"></i>
                            <b>Click to Contribute</b>
                          </Button>
                        </Col>
                      </Row>
                    } */}
                    {/* {memberAccount &&

                      <Button
                        className="btn-round"
                        color="primary"
                        to="/dmPasss"
                        id="upgrade-to-pro"
                        tag={Link}
                      >

                        <b>Click to Contribute More</b>
                      </Button>

                    } */}
                    <Modal
                      isOpen={modalLarge}
                      className="modal-lg"
                      modalClassName="bd-example-modal-lg"
                      toggle={() => setModalLarge(false)}
                    >
                      <ModalHeader>

                        <h5 className="modal-title" id="myLargeModalLabel">
                          <b>Contribution Details</b>
                        </h5>
                        <button
                          aria-label="Close"
                          className="close"
                          type="button"
                          onClick={() => setModalLarge(false)}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </ModalHeader>
                      <ModalBody>
                        <Table responsive>
                          <thead class="text-white">
                            <tr class="bg-primary">
                              <td><b>Plan Name</b></td>
                              <td><b>Quantity</b></td>
                              <td><b>Contribution</b></td>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredOrder !== null && filteredOrder[0] !== null && filteredOrder[0].selectedProducts !== null && filteredOrder[0].selectedProducts.map((prd) => (
                              <tr>
                                <td class="text-left">{prd.name}</td>
                                <td>{prd.qty}</td>
                                <td>{prd.sellPrice}</td>
                              </tr>
                            ))
                            }

                            {filteredOrder !== null && filteredOrder[0] &&
                              <tr class="bg-secondary text-white">
                                <td colSpan={2} class="text-left"><b>Total</b></td>
                                <td><b>{filteredOrder[0].orderTotal}</b></td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                      </ModalBody>
                      <ModalFooter>
                        {filteredOrder !== null && filteredOrder[0] &&
                          <Table responsive borderless="true">
                            <tbody>
                              <tr>
                                <td><b>Contribution Id</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].orderRcptId}</td>
                              </tr>
                              <tr>
                                <td><b>Contribution Status</b></td>
                                <td colSpan={3} class="text-left">{filteredOrder[0].status}</td>
                              </tr>
                            </tbody>
                          </Table>
                        }
                      </ModalFooter>
                    </Modal>

                    <Modal
                      isOpen={modalLargeN}
                      className="modal-lg"
                      modalClassName="bd-example-modal-lg"
                      toggle={() => setModalLarge(false)}
                    >
                      <ModalHeader>
                        <h5 className="modal-title" id="myLargeModalLabel">
                          <b>Pay and Share deatils</b>
                        </h5>
                        <button
                          aria-label="Close"
                          className="close"
                          type="button"
                          onClick={() => setModalLargeN(false)}
                        >
                          <span aria-hidden={true}>×</span>
                        </button>
                      </ModalHeader>
                      <ModalBody className="text-center">
                        <Row>
                          <Col md="6">
                            <Card border="primary" className="nav-tabs text-center" outline="true">
                              <CardTitle tag="h4">QR Code</CardTitle>
                              <div>
                                <img alt="..." src={require("assets/img/sbi_qr.jpeg")}></img>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">

                            <Card border="primary" className="nav-tabs text-center" outline="true">
                              <CardTitle tag="h4">Account Details</CardTitle>
                              <Row>
                                <Col>
                                  <div>
                                    <img alt="..." src={require("assets/img/axis.png")}></img>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="text-left">
                                <Col>
                                  <h5><b>Account Payee: </b>Sarjapur Outer Ring Road Bengali Association</h5>
                                  <h5><b>Account ID: </b>912010038909637</h5>
                                  <h5><b>IFSC Code: </b>UTIB0002145</h5>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card border="primary" className="nav-tabs text-center" outline="true">
                              <CardTitle tag="h4">Plaese pay manually and record Transaction Details for <b>&#x20B9;{orderTotal}</b></CardTitle>
                              <CardBody>
                                <InputGroup
                                  className={
                                    "input-lg" +
                                    (offlineTxnIdFocus ? " input-group-focus" : "")
                                  }
                                >
                                  <Input
                                    placeholder="Bank Transaction ID"
                                    type="text"
                                    onFocus={() => setOfflineTxnIdFocus(true)}
                                    onBlur={() => setOfflineTxnIdFocus(false)}
                                    onChange={(e) => setOfflineTxnId(e.target.value)}
                                  ></Input>
                                </InputGroup>
                                <InputGroup
                                  className={
                                    "input-lg" +
                                    (offlineBankNameFocus ? " input-group-focus" : "")
                                  }
                                >

                                  <Input
                                    placeholder="Bank Name"
                                    type="text"
                                    onFocus={() => setOfflineBankNameFocus(true)}
                                    onBlur={() => setOfflineBankNameFocus(false)}
                                    onChange={(e) => setOfflineBankName(e.target.value)}
                                  ></Input>
                                </InputGroup>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" onClick={(e) => { handleOfflineUpdDetails(e) }}><b>Update Payment Details</b></Button>
                      </ModalFooter>
                    </Modal>
                  </TabPane>

                  {/*<TabPane tabId="plainTabs3">*/}
                  {/*  <Card>*/}
                  {/*    <CardBody>*/}
                  {/*      <CardTitle tag="h3">Your Current Wallet Balance</CardTitle>*/}
                  {/*      <CardText tag="h2">*/}
                  {/*        <b>{userProfile === null || userProfile.walletBalance === null || userProfile.walletBalance === undefined ? 0 : userProfile.walletBalance}</b>*/}
                  {/*      </CardText>*/}
                  {/*      <Button*/}
                  {/*        color="primary"*/}
                  {/*        href="#addMoneyToWallet"*/}
                  {/*        onClick={e => handleAddMoney()}*/}
                  {/*      >*/}
                  {/*        [+] Add Money to Wallet*/}
                  {/*      </Button>*/}
                  {/*    </CardBody>*/}
                  {/*  </Card>*/}

                  {/*  <Modal isOpen={addMoneyModal}>*/}
                  {/*    <div className="modal-header">*/}
                  {/*      <h5 className="modal-title" id="addMoneyModal">*/}
                  {/*        Add Money to Your Wallet*/}
                  {/*      </h5>*/}
                  {/*      <button*/}
                  {/*        aria-label="Close"*/}
                  {/*        className="close"*/}
                  {/*        type="button"*/}
                  {/*        onClick={() => setAddMoneyModal(false)}*/}
                  {/*      >*/}
                  {/*        <span aria-hidden={true}>×</span>*/}
                  {/*      </button>*/}
                  {/*    </div>*/}

                  {/*    <ModalBody>*/}
                  {/*      <Card className="card-login card-plain">*/}
                  {/*        <Form action="" className="form" method="">*/}
                  {/*          <CardBody>*/}
                  {/*            <InputGroup*/}
                  {/*              className={*/}
                  {/*                "no-border" + (amountFocus ? " input-group-focus" : "")*/}
                  {/*              }*/}
                  {/*            >*/}
                  {/*              <Input*/}
                  {/*                placeholder="* Amount to Contribute"*/}
                  {/*                type="text"*/}
                  {/*                value={amount}*/}
                  {/*                onFocus={() => setAmountFocus(true)}*/}
                  {/*                onBlur={() => setAmountFocus(false)}*/}
                  {/*                onChange={(e) => handleAmountChange(e)}*/}
                  {/*              ></Input>*/}
                  {/*            </InputGroup>*/}
                  {/*            <Row className="text-right">*/}
                  {/*              <Col className="text-right">*/}
                  {/*                <Button*/}
                  {/*                  block*/}
                  {/*                  className="btn-round btn-link"*/}
                  {/*                  color="primary"*/}
                  {/*                  onClick={() => addMoreMoney(amount, 100)}*/}
                  {/*                  size="sm"*/}
                  {/*                >*/}
                  {/*                  + 100*/}
                  {/*                </Button>*/}
                  {/*              </Col >*/}
                  {/*              <Col className="text-right">*/}
                  {/*                <Button*/}
                  {/*                  block*/}
                  {/*                  className="btn-round btn-link"*/}
                  {/*                  color="primary"*/}
                  {/*                  onClick={() => addMoreMoney(amount, 200)}*/}
                  {/*                  size="sm"*/}
                  {/*                >*/}
                  {/*                  + 200*/}
                  {/*                </Button>*/}
                  {/*              </Col>*/}
                  {/*              <Col className="text-right">*/}
                  {/*                <Button*/}
                  {/*                  block*/}
                  {/*                  className="btn-round btn-link"*/}
                  {/*                  color="primary"*/}
                  {/*                  onClick={() => addMoreMoney(amount, 500)}*/}
                  {/*                  size="sm"*/}
                  {/*                >*/}
                  {/*                  + 500*/}
                  {/*                </Button>*/}
                  {/*              </Col>*/}
                  {/*              <Col className="text-right">*/}
                  {/*                <Button*/}
                  {/*                  block*/}
                  {/*                  className="btn-round btn-link"*/}
                  {/*                  color="primary"*/}
                  {/*                  onClick={() => addMoreMoney(amount, 1000)}*/}
                  {/*                  size="sm"*/}
                  {/*                >*/}
                  {/*                  + 1000*/}
                  {/*                </Button>*/}
                  {/*              </Col>*/}
                  {/*            </Row>*/}
                  {/*          </CardBody>*/}
                  {/*          <CardFooter className="text-center">*/}
                  {/*            <Button*/}
                  {/*              block*/}
                  {/*              className="btn-round"*/}
                  {/*              color="danger"*/}
                  {/*              onClick={() => handleAddMoneyProceed()}*/}
                  {/*              size="lg"*/}
                  {/*            >*/}
                  {/*              + Add Money*/}
                  {/*            </Button>*/}
                  {/*          </CardFooter>*/}
                  {/*        </Form>*/}
                  {/*      </Card>*/}
                  {/*    </ModalBody>*/}
                  {/*  </Modal>*/}
                  {/*</TabPane>*/}

                </TabContent>
              </CardBody>
            </Card>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default ProfilePage;
