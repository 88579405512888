import React from "react";

// reactstrap components
import {Card, CardBody, CardTitle, Col, Container, Row, Table,} from "reactstrap";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";

function SponsoredByPage() {

  React.useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Our Sponsors</h2>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4"><b>Full Sponsorship - 2023</b></CardTitle>
                  <CardBody>
                    <Table responsive>
                      <thead className="text-white text-left bg-primary">
                      <tr>
                        <th>Name of Sponsorship Item</th>
                        <th>Sponsored By</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr className="text-left">
                        <td><b>Durga Idol</b></td>
                        <td><b>Susmita maity</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Brahmacharini</b></td>
                        <td><b>Ayriaunaa / Avik Kundu</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Siddhidaatri</b></td>
                        <td><b>Jayanta Tewari / Sharmistha Chakraborty</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Kaalratri</b></td>
                        <td><b>Jayanta Tewari / Sharmistha Chakraborty</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Mahagauri</b></td>
                        <td><b>Somak Banik</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Sandhi Puja</b></td>
                        <td><b>Shrabanti / Sudip Debnath</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Laxmi Idol</b></td>
                        <td><b>Abhik Bhattacharyya</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Kali Idol</b></td>
                        <td><b>Anirban / Sushmita Bhattacharya</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Swaraswati Idol</b></td>
                        <td><b>Subrata Datta</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Jagya</b></td>
                        <td><b>Meera Sen</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Puja Upachar - Shashti</b></td>
                        <td><b>Indranil Sinha</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Puja Upachar - Saptami</b></td>
                        <td><b>Sanjib Banerjee</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Puja Upachar - Ashtami</b></td>
                        <td><b>Sabyasachi Mukhopadhyay</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Puja Upachar - Nabami</b></td>
                        <td><b>Satyapriya Gupta & Sathi Gupta</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Thakurer Mala - Saptami</b></td>
                        <td><b>Saroj Ghosh</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Thakurer Mala - Ashtami</b></td>
                        <td><b>Nirmalya Sinha</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Thakurer Mala - Nabami</b></td>
                        <td><b>Bibhas / Manoka Nandi</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Flowers - Saptami</b></td>
                        <td><b>Priya Pathya</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Flowers - Ashtami</b></td>
                        <td><b>Sudip Debnath</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Flowers - Nabami</b></td>
                        <td><b>Arindam Roy</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Mandap Shojja</b></td>
                        <td><b>Debasish banerjee</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Kola Bou Saree</b></td>
                        <td><b>Kajal Mandal</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Prasad - Shashti</b></td>
                        <td><b>Anirban Biswas</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Prasad - Saptami</b></td>
                        <td><b>Minakshi Choudhury</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Prasad - Ashtami</b></td>
                        <td><b>Subhanil Khasnobish</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Prasad - Nabami</b></td>
                        <td><b>Supratim / Tanumita Mukherjee</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Dhaki - Ashtami</b></td>
                        <td><b>Abir Mukhopadhyay</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Dhaki - Dashami</b></td>
                        <td><b>Rajib Basu</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Sandhya Arati - Saptami</b></td>
                        <td><b>Shirsendu Deb</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Sandhya Arati - Ashtami</b></td>
                        <td><b>Ruby Deb</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Sandhya Arati - Nabami</b></td>
                        <td><b>Santasina Deb</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Lakshmi Arati</b></td>
                        <td><b>Anshuman Ghosh</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Lakshmi Mala</b></td>
                        <td><b>Arindam / Moitri Paul</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Lakshmi Upachar</b></td>
                        <td><b>Arindam / Moitri Paul</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Lakshmi Flower</b></td>
                        <td><b>Arindam / Moitri Paul</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Lakshmi Pujo Purohit</b></td>
                        <td><b>Supratim / Tanumita Mukherjee</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Lakshmi Pujo Mandap Shojja</b></td>
                        <td><b>Ayriaunaa / Avik Kundu</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Lakshmi Pujo Dadhi karma</b></td>
                        <td><b>Arindam / Moitri Paul</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Lakshmi Thakur Prasad</b></td>
                        <td><b>Ayriaunaa / Avik Kundu</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Pujo Flowers</b></td>
                        <td><b>Swetasri Chowdhury</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Pujo Arati</b></td>
                        <td><b>Abanindra Sarkar</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Pujo Prasad</b></td>
                        <td><b>Abanindra Sarkar</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Puja Community Bhog</b></td>
                        <td><b>Sourish Pal</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Puja Dodhi Karma</b></td>
                        <td><b>Sourish Pal</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Kali Puja Upachar</b></td>
                        <td><b>Joy Burman</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Kali Puja Prasad</b></td>
                        <td><b>Joy Burman</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Puja Puja Upachar</b></td>
                        <td><b>Subhasish Dutta</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Puja Thakurer Mala</b></td>
                        <td><b>Subhasish Dutta</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Puja Mandap Shojja</b></td>
                        <td><b>Subhasish Dutta</b></td>
                      </tr>
                      <tr className="text-left">
                        <td><b>Saraswati Puja Purohit</b></td>
                        <td><b>Subhasish Dutta</b></td>
                      </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4"><b>Full Sponsorship - 2022</b></CardTitle>
                  <CardBody>
                    <Table responsive>
                      <thead class="text-white text-left">
                      <tr class="bg-primary">
                        <td><b><b>Name of Sponsorship Item</b></b></td>
                        <td><b><b>Sponsored By</b></b></td>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="text-left">
                        <td>
                          <b>Durga Idol</b>
                        </td>
                        <td>
                          <b>Anirban/Sushmita Bhattacharya</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Lakshmi Idol</b>
                        </td>
                        <td>
                          <b>Somak/Gayatri Banik</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Kali Idol</b>
                        </td>
                        <td>
                          <b>Anirvan/Arpita Mukherjee</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Saraswati Idol</b>
                        </td>
                        <td>
                          <b>Subrata Dutta</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Kala Bou Saree</b>
                        </td>
                        <td>
                          <b>Indranil/Runa Sinha</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Sandhi Pujo</b>
                        </td>
                        <td>
                          <b>Saroj/Sumita Ghosh</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Saraswati Pujo</b>
                        </td>
                        <td>
                          <b>Meera Sen</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Sashti Puja Upachar</b>
                        </td>
                        <td>
                          <b>Minakshi Choudhury</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Sashti Prasad</b>
                        </td>
                        <td>
                          <b>Sabyasachi Mukhopadhyay</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Ashtami Thakurer Mala</b>
                        </td>
                        <td>
                          <b>Subhashis Basak and Adit Basak</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Ashtami Puja Upachar</b>
                        </td>
                        <td>
                          <b>Subhonil Khasnobish</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Ashtami Dhaki</b>
                        </td>
                        <td>
                          <b>Avik/Ayriaunaa Kundu</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                        <b>Saptami Thakurer Mala</b>
                        </td>
                        <td>
                          <b>Durba Chakraborty</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Ashtami Prasad</b>
                        </td>
                        <td>
                          <b>Debasish Banerjee</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Nabami Jagya</b>
                        </td>
                        <td>
                          <b>Shirsendu/Santasina Deb</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Lakshmi Pujo</b>
                        </td>
                        <td>
                          <b>Priyanka Biswas/Rajdip Ghosh</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Kali Pujo</b>
                        </td>
                        <td>
                          <b>Sharmishta/Jayanta Tewari</b>
                        </td>
                      </tr>
                      <tr class="text-left">
                        <td>
                          <b>Saptami Puja Upachar</b>
                        </td>
                        <td>
                            <b>Subhasish/Nidhi Dutta</b>
                          </td>
                        </tr>
                        <tr class="text-left">
                          <td>
                            <b>Saptami Prasad</b>
                          </td>
                          <td>
                            <b>Nirmalya Sinha</b>
                          </td>
                        </tr>
                        <tr class="text-left">
                          <td>
                            <b>Nabami Prasad</b>
                          </td>
                          <td>
                            <b>Rajat Das</b>
                          </td>
                        </tr>
                        <tr class="text-left">
                          <td>
                            <b>Nabami Flowers</b>
                          </td>
                          <td>
                            <b>Abanindra Sarkar</b>
                          </td>
                        </tr>
                        <tr class="text-left">
                          <td>
                            <b>Nabami Thakurer Mala </b>
                          </td>
                          <td>
                            <b>Priyabrata Kundu</b>
                          </td>
                        </tr>
                        <tr class="text-left">
                          <td>
                            <b>Flowers Saptami</b>
                          </td>
                          <td>
                            <b>Sanjib Banerjee</b>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4"><b>Co-sponsorship - 2022</b></CardTitle>
                  <CardBody>
                    <Table responsive>
                      <thead class="text-white text-left">
                        <tr class="bg-primary">
                          <td><b><b>Name of Sponsorship Item</b></b></td>
                          <td><b><b>Sponsored By</b></b></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-left">
                          <td rowSpan={10}><b>Ashtami Sandhya Aroti</b></td>
                          <td><b>Debapriya Nag</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Abir Mukhopadhyay</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sajjita Chattopadhaya</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Dey</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Sarkar</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Asim Pattanayak</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Deb Jyoti Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Bijayeeta Bhattacharya</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anish De</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={4}><b>Nabami Sandhya Aroti</b></td>
                          <td><b>Debapriya Nag</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Dey</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Deb Jyoti Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anish De</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={13}><b>Puja Upachar Nabami</b></td>
                          <td><b>Saumitra Kumar Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Debapriya Nag</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Meghmala Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Ankita Saha</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Tapan Dutta</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Ashish Kumar Verma</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Dey</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Shefali Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Kuntal Basu</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Samik Nandi</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Priyanka Bhattacharya</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sangita Nandi</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Avijit Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={2}><b>Shashti Dhaki</b></td>
                          <td><b>Pranabesh Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Shefali Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={2}><b>Sandhya Arati Saptami</b></td>
                          <td><b>Anshuman Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Dey</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={7}><b>Community Bhog Ashtami</b></td>
                          <td><b>Shashanka Kundu</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Srabosti Bhattacharya</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Joyita Banerjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Subhashish Lahiri</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Supratim Ray</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Amitav Bhattacharjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Debashish Chatterjee</b></td>
                        </tr>

                        <tr class="text-left">
                          <td rowSpan={1}><b>Community Bhog Nabami</b></td>
                          <td><b>Subhashish Lahiri</b></td>
                        </tr>

                        <tr class="text-left">
                          <td rowSpan={1}><b>Dhaki Nabami</b></td>
                          <td><b>Prasanta Kumar Chaudhuri</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={3}><b> Dhaki Dashami</b></td>
                          <td><b>Jaydeep Bose</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Purbasa Pramanik</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Surashree Roy</b></td>
                        </tr>

                        <tr class="text-left">
                          <td rowSpan={7}><b>Flowers Ashtami</b></td>
                          <td><b>Ishita Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Raja Ukil</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Moitri Paul</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Samik Nandi</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Avijit Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anish De</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anindita Hajra</b></td>
                        </tr>

                        <tr class="text-left">
                          <td rowSpan={2}><b>Community Bhog Saptami</b></td>
                          <td><b>Purbasa Pramanik</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Debdatta Bandopadhyay</b></td>
                        </tr>

                        <tr class="text-left">
                          <td rowSpan={2}><b>Dhaki Saptami</b></td>
                          <td><b>Prasanta Kumar Chaudhuri</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Debdatta Bandopadhyay</b></td>
                        </tr>

                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4"><b>Full Sponsorship - 2020</b></CardTitle>
                  <CardBody>
                    <Table responsive>
                      <thead class="text-white text-left">
                        <tr class="bg-primary">
                          <td><b><b>Name of Sponsorship Item</b></b></td>
                          <td><b><b>Sponsored By</b></b></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-left">
                          <td><b>Durga Idol</b></td>
                          <td><b>Sudip Debnath</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sandhi Pujo</b></td>
                          <td><b>Neela Mukherjee W/o Late Deepak Kumar Rai</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Jagya</b></td>
                          <td><b>Anirban Bhattacharya</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Bodhon</b></td>
                          <td><b>Priyanka Biswas</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sashti Pujo Upachar</b></td>
                          <td><b>Sudipto Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sashti Kola Bou Saree</b></td>
                          <td><b>Rita Chattoraj</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sashti Thakurer Mala</b></td>
                          <td><b>Sukrishna Dutta</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sashti Flower</b></td>
                          <td><b>Saroj Kumar Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sashti Thakurer Prasad Nibedan</b></td>
                          <td><b>Nirmalya Sinha</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saptami Pujo Upachar</b></td>
                          <td><b>Avik Kundu</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saptami Thakurer Mala </b></td>
                          <td><b>Sanjay Gupta</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saptami Pujo Flower</b></td>
                          <td><b>Subhanil Khasnobish</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saptami Thakurer Prasad Nibedan</b></td>
                          <td><b>Tapan Dutta</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saptami Sandhya Aroti </b></td>
                          <td><b>Deb Jyoti Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Ashtami Thakurer Mala</b></td>
                          <td><b>Moumita Mondal</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Ashtami Pujo Flowers</b></td>
                          <td><b>Debasish Bannerjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Ashtami Thakurer Prasad Nibedan</b></td>
                          <td><b>Abhik Bhattacharya</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Ashtami Sandhya Aroti</b></td>
                          <td><b>Sharmistha Chakraborty</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Puja Upachar</b></td>
                          <td><b>Shefali Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Thakurer Mala</b></td>
                          <td><b>Subhasish Dutta</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Puja Flower</b></td>
                          <td><b>Paromita Sarkar</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Thakurer Prasad Nibedan</b></td>
                          <td><b>Ishita Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>NabamiSandhya Aroti</b></td>
                          <td><b>Suman Mitra</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Mantap Decoration</b></td>
                          <td><b>Bidyut Choudhuri</b></td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4"><b>Full Sponsorship - 2019</b></CardTitle>
                  <CardBody>
                    <Table responsive>
                      <thead class="text-white text-left">
                        <tr class="bg-primary">
                          <td><b><b>Name of Sponsorship Item</b></b></td>
                          <td><b><b>Sponsored By</b></b></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-left">
                          <td><b>Durga Idol</b></td>
                          <td><b>Anirban Bhattacharjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Lakshmi Idol</b></td>
                          <td><b>Tamsa / Abhik  Bhattacharya</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Kali Idol</b></td>
                          <td><b>Bhavna Singh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saraswati Idol</b></td>
                          <td><b>Indranil Sinha</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sandhi Pujo</b></td>
                          <td><b>Sudip Debnath</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Lakshmi Pujo</b></td>
                          <td><b>Abhijit Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Kali Pujo</b></td>
                          <td><b>Arindam / Moitree Paul</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saraswati Pujo</b></td>
                          <td><b>Rajat Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sashti Puja Upachar</b></td>
                          <td><b>Sougata Chatterjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saptami Thakurer Mala </b></td>
                          <td><b>Raja Ukil</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Thakurer Mala </b></td>
                          <td><b>Ruby Deb</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sashti Prasad</b></td>
                          <td><b>Debasish Bannerjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saptami Prasad</b></td>
                          <td><b>Niloy Mukherjee / Krishna Paul</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Ashtami Prasad</b></td>
                          <td><b>Nirmalya Sinha</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Prasad</b></td>
                          <td><b>Sanjib Bannerjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sashti Dhaki</b></td>
                          <td><b>Anjali / Kajal Mondal</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Saptami Dhaki</b></td>
                          <td><b>Rajesh Sahai</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Dhaki</b></td>
                          <td><b>Joyeeta Bannerjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sandhya Aroti Saptami</b></td>
                          <td><b>Priyabrata  Kundu</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sandhya Aroti Nabami</b></td>
                          <td><b>Meera Sen</b></td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4"><b>Co-Sponsorship - 2019</b></CardTitle>
                  <CardBody>
                    <Table responsive>
                      <thead class="text-white text-left">
                        <tr class="bg-primary">
                          <td><b><b>Name of Sponsorship Item</b></b></td>
                          <td><b><b>Sponsored By</b></b></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="text-left">
                          <td rowSpan={3}><b>Kola Bou Saree</b></td>
                          <td><b>Soumili Biswas</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Rajib Basu</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Paromita Sarkar</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={3}><b>Nabami Jagya</b></td>
                          <td><b>Deep Moitra</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Paromita Sarkar</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Soumili Biswas</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={3}><b>Ashtami Thakurer Mala</b></td>
                          <td><b>Anindya Ray</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Basu</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sudipto Kumar Roy</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={8}><b>Ashtami Flowers</b></td>
                          <td><b>Dhrubo Banerjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Subhankar Chakrabarti</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anwesha Chatterji</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Shefali Das</b></td>
                        </tr>
                        <tr className="text-left">
                          <td><b>Sanjoy Parial</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Kiriti Misra</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Somak Banik</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Debleena Majumdar</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={3}><b>Saptami Community Bhog</b></td>
                          <td><b>Krishnendu Mukherjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Subrato Nath</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Indraneil Roy</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={8}><b>Ashtami Community Bhog</b></td>
                          <td><b>Ronojoy Roy</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Shubhashis Sengupta</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Udit Mitra</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Krishnendu Mukherjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Sarkar</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Subrato Nath</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Shantanu Banerjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Suchismita Roy</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={15}><b>Ashtami Sandhya Aroti</b></td>
                          <td><b>Partha De Sarkar</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Joydeep Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Deb Jyoti Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anshuman Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Rajib Basu</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sourish Pal</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anirvan Mukherjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sujoy Bhattacharjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Piyali Biswas</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Bidyut Chowdhury</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Dipankar Das</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sangita Nandi</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Subhasish (John) Basak</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Prithviraj Choudhury</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Prasanta Chaudhuri </b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={9}><b>Ashtami Puja Upachar</b></td>
                          <td><b>Niloy Mukherjee, Krishna Paul</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Birjendu Gupta</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Joydeep Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anindya Ray</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sabuj Sen</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Abhijit Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Arindam Basu</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Anshuman Ghosh</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Sanjay Gupta</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Nabami Puja Upachar</b></td>
                          <td><b>Subhasish (John) Basak</b></td>
                        </tr>
                        <tr class="text-left">
                          <td rowSpan={3}><b>Ashtami Dhaki</b></td>
                          <td><b>Amitav Bhattacharjee</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Udit Mitra</b></td>
                        </tr>
                        <tr class="text-left">
                          <td><b>Prithviraj Choudhury</b></td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default SponsoredByPage;
