import React, {useState} from "react";

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col,
    Container,
    Input,
    InputGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
} from "reactstrap";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {APIRequest} from "utils/ApiRequest";
import {useNavigate} from "react-router";
import {toast} from "react-toastify";

function OrderSummary() {
  const [token, setToken] = useState(null);
  const history = useNavigate();
  const [cartRes, setCartRes] = useState(null);
  const [modalLarge, setModalLarge] = React.useState(false);
  const [modalLargeFinal, setModalLargeFinal] = React.useState(false);
  const [offlineTxnId, setOfflineTxnId] = useState(null);
  const [offlineBankName, setOfflineBankName] = useState(null);
  const [orderRcptId, setOrderRcptId] = useState(null);
  const [orderTotal, setOrderTotal] = useState(null);
  const [offlineTxnIdFocus, setOfflineTxnIdFocus] = React.useState(false);
  const [offlineBankNameFocus, setOfflineBankNameFocus] = React.useState(false);
  const [address1Focus, setAddress1Focus] = React.useState(false);
  const [address2Focus, setAddress2Focus] = React.useState(false);
  const [cityFocus, setCityFocus] = React.useState(false);
  const [stateFocus, setStateFocus] = React.useState(false);
  const [pinFocus, setPinFocus] = React.useState(false);
  const [emailExFocus, setEmailExFocus] = React.useState(false);
  const [mobileExFocus, setMobileFocus] = React.useState(false);
  const [addrLine1, setAddrLine1] = useState(null);
  const [addrLine2, setAddrLine2] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [pin, setPin] = useState(null);
  const [emailEx, setEmailEx] = useState(null);
  const [mobileEx, setMobileEx] = useState(null);
  const [referSource, setReferSource] = useState(null);
  const [referredBy, setReferredBy] = useState(null);
  const [referSourceFocus, setReferSourceFocus] = React.useState(false);
  const [referredByFocus, setReferredByFocus] = React.useState(false);


  React.useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let userInfo = localStorage.getItem('user-info');
    if (userInfo !== null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session !== null) {
        setToken(userInfo.session);
        loadCartData(userInfo.session);
        loadUserExData(userInfo.session)
      } else {
        history("/login")
      }
    } else {
      history("/login")
    }

    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function loadCartData(token) {
    APIRequest(
      token,
      "/getCartDetails",
      {},
      (res) => {
        console.log(res.data);
        if (res.data === null || res.data.length === 0) history("/membership");
        setCartRes(res.data);
      },
    );
  }

  async function loadUserExData(token) {
    APIRequest(
      token,
      "/getUserExtraInfo",
      {},
      (res) => {
        let userExtra = res.data;
        if (userExtra === null) return;
        setAddrLine1(userExtra.addrLine1);
        setAddrLine2(userExtra.addrLine2);
        setCity(userExtra.city);
        setState(userExtra.state);
        setPin(userExtra.pin);
        setEmailEx(userExtra.additionalEmail);
        setMobileEx(userExtra.additionalMobile);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async function saveUserAdditionalDetails() {
    APIRequest(
      token,
      "/saveUserExtraInfo",
      { addrLine1, addrLine2, pin, city, state, additionalEmail: emailEx, additionalMobile: mobileEx, referSource, referredBy },
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  }


  const handleOnlineOrderNow = (e) => {
    if (addrLine1 === null || city === null || state === null || pin === null || referSource === null || referSource === "" || (referSource === "Other" && referredBy === null)) {
      toast.error("Please fill the mandatory(*) fields!");
      return;
    }
    APIRequest(
      token,
      "/startOrder",
      {
        offline: false
      },
      (res) => {
        loadRazorPay(res.data);
        saveUserAdditionalDetails();
        deleteCart();
      },
    );
    e.preventDefault();
  }

  const handleOfflineOrderNow = (e) => {
    if (addrLine1 === null || city === null || state === null || pin === null || referSource === null || referSource === "" || (referSource === "Other" && referredBy === null)) {
      toast.error("Please fill the mandatory(*) fields!");
      return;
    }
    APIRequest(
      token,
      "/startOrder",
      {
        offline: true
      },
      (res) => {
        loadOfflinePay(res.data);
        saveUserAdditionalDetails();
        deleteCart();
      },
    );
    e.preventDefault();
  }

  const handleUpdTxnDetails = (e) => {
    setModalLarge(false);
    setModalLargeFinal(true);
  }

  const handleOfflineUpdDetails = (e) => {
    if (offlineTxnId === undefined || offlineTxnId === null || offlineTxnId === "") {
      toast.error("Please add the Bank/UPI transaction id to proceed");
      return;
    }
    APIRequest(
      token,
      "/handleOfflineOrder",
      { isOffline: true, offlineTxnId, offlineBankName, orderRcptId },
      () => {
        localStorage.removeItem('selectedPrds');
        localStorage.removeItem('totalCartPrice');
        let userInfo = localStorage.getItem('user-info');
        if (userInfo != null) {
          userInfo = JSON.parse(userInfo);
          userInfo.member = true;
          localStorage.setItem('user-info', JSON.stringify(userInfo));
        }
        toast.success('Your contribution deatils has been captured and sent for verfication, will sent you an email on once verification completes!');
        history("/profile");
      },
    );
    e.preventDefault();
  }

  function loadOfflinePay(order) {
    setOrderRcptId(order.orderRcptId);
    setOrderTotal(order.orderTotalInPaisa / 100);
    setModalLarge(true);
    setModalLargeFinal(false);
  }


  async function deleteCart() {
    APIRequest(
      token,
      "/deleteCart",
      {},
      (res) => {
        console.log(res.data);
        localStorage.removeItem('selectedPrds');
        localStorage.removeItem('totalCartPrice');
      },
    );
  }

  const handleStartOver = (e) => {
    localStorage.removeItem('selectedPrds');
    localStorage.removeItem('totalCartPrice');
    history.goBack();
    history.goBack();
  }

  const handleModalClose = () => {
    setModalLargeFinal(false);
    setModalLarge(false);
    deleteCart();
    localStorage.removeItem('selectedPrds');
    localStorage.removeItem('totalCartPrice');
    history("/profile");
  }

  async function loadRazorPay(order) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => {
        const options = {
          key: 'rzp_live_7n7vOYR9yX50XZ',
          currency: order.currency,
          amount: order.orderTotalInPaisa,
          order_id: order.orderOrTokenId,
          name: 'Membership Contibution',
          description: 'Contribution for SORRBA Membership',
          image: 'https://app.sorrba.org/static/media/now-logo.9315a628.png',
          handler: function (response) {
            APIRequest(
              token,
              "/handleSuccessOrder",
              { isOffline: false, orderId: response.razorpay_order_id, razorpayPaymentId: response.razorpay_payment_id, razorpaySignature: response.razorpay_signature },
              (res) => {
                localStorage.removeItem('selectedPrds');
                localStorage.removeItem('totalCartPrice');
                let userInfo = localStorage.getItem('user-info');
                if (userInfo != null) {
                  userInfo = JSON.parse(userInfo);
                  userInfo.member = true;
                  localStorage.setItem('user-info', JSON.stringify(userInfo));
                }
                toast.success('Congratulations!! We received your contibution, Please check you email for datails!');
                history("/profile");
              },
            );
          },
          modal: {
            "ondismiss": function () {
              toast.error('Payment Failed, Please start over!');
              history.goBack();
              history.goBack();
              history.goBack();
            }
          },
          prefill: {
            name: order.userFullName,
            email: order.userEmail,
            contact: order.userMobile
          }
        }
        const paymentObject = new window.Razorpay(options);
        paymentObject.on('payment.failed', function (response) {
          APIRequest(
            token,
            "/handleFailOrder",
            { orderId: response.error.metadata.order_id, razorpayPaymentId: response.error.metadata.payment_id },
            (res) => {
              localStorage.removeItem('selectedPrds');
              localStorage.removeItem('totalCartPrice');
              toast.error('Payment failed, order couldn\'t be processed successfully!');
              history.goBack();
              history.goBack();
              history.goBack();
            },
          );
        });
        paymentObject.open();
      };
      script.onerror = () => {
        toast.error('Seems some internal issue with your network, please check your network!');
      }
      document.body.appendChild(script);
    });
  }

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <Container>
            <Row className="text-center">
              <Col className="text-center" md="12">
                <h2 className="title">Checkout</h2>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4"><b>Summary</b></CardTitle>
                  <CardBody>
                    <Table responsive>
                      <thead class="text-white">
                        <tr class="bg-primary">
                          <td><b>Contibution Category</b></td>
                          <td><b>Unit</b></td>
                          <td><b>Contribution</b></td>
                          <td><b>Benefit</b></td>
                          <td><b>Amount</b></td>
                        </tr>
                      </thead>
                      <tbody>
                        { }
                        {cartRes !== null && cartRes.selectedProducts !== null && cartRes.selectedProducts.map((prd) => (
                          <tr>
                            <td class="text-left">{prd.name}</td>
                            <td>{prd.qty}</td>
                            <td>&#x20B9;{prd.maxPrice * prd.qty}</td>
                            <td>&#x20B9;{(prd.maxPrice - prd.sellPrice) * prd.qty}</td>
                            <td>&#x20B9;{prd.sellPrice * prd.qty}</td>
                          </tr>
                        ))
                        }

                        {cartRes !== null &&
                          <tr class="bg-secondary text-white">
                            <td colSpan={2} class="text-left"><b>Total</b></td>
                            <td><b>&#x20B9;{cartRes.totalMaxPrice}</b></td>
                            <td><b>&#x20B9;{cartRes.totalMaxPrice - cartRes.totalSellPrice}</b></td>
                            <td><b>&#x20B9;{cartRes.totalSellPrice}</b></td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4"><b>Address and additional Details</b></CardTitle>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <InputGroup
                          className={
                            "input-lg" +
                            (address1Focus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="* Door no, Building/Society name"
                            type="text"
                            value={addrLine1}
                            onFocus={() => setAddress1Focus(true)}
                            onBlur={() => setAddress1Focus(false)}
                            onChange={(e) => setAddrLine1(e.target.value)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <InputGroup
                          className={
                            "input-lg" +
                            (address2Focus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="Road Name, Area, etc"
                            type="text"
                            value={addrLine2}
                            onFocus={() => setAddress2Focus(true)}
                            onBlur={() => setAddress2Focus(false)}
                            onChange={(e) => setAddrLine2(e.target.value)}
                          ></Input>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <InputGroup
                          className={
                            "input-lg" +
                            (cityFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="* City"
                            type="text"
                            value={city}
                            onFocus={() => setCityFocus(true)}
                            onBlur={() => setCityFocus(false)}
                            onChange={(e) => setCity(e.target.value)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <InputGroup
                          className={
                            "input-lg" +
                            (stateFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="* State"
                            type="text"
                            value={state}
                            onFocus={() => setStateFocus(true)}
                            onBlur={() => setStateFocus(false)}
                            onChange={(e) => setState(e.target.value)}
                          ></Input>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <InputGroup
                          className={
                            "input-lg" +
                            (pinFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="* Pin"
                            type="text"
                            value={pin}
                            onFocus={() => setPinFocus(true)}
                            onBlur={() => setPinFocus(false)}
                            onChange={(e) => setPin(e.target.value)}
                          ></Input>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <InputGroup
                          className={
                            "input-lg" +
                            (mobileExFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="Additional Mobile No (Optional)"
                            type="text"
                            value={mobileEx}
                            onFocus={() => setMobileFocus(true)}
                            onBlur={() => setMobileFocus(false)}
                            onChange={(e) => setMobileEx(e.target.value)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <InputGroup
                          className={
                            "input-lg" +
                            (emailExFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            placeholder="Additional Email ID (Optional)"
                            type="text"
                            value={emailEx}
                            onFocus={() => setEmailExFocus(true)}
                            onBlur={() => setEmailExFocus(false)}
                            onChange={(e) => setEmailEx(e.target.value)}
                          ></Input>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <InputGroup
                          className={
                            "input-lg" +
                            (referSourceFocus ? " input-group-focus" : "")
                          }
                        >
                          <Input
                            type="select"
                            value={referSource}
                            onFocus={() => setReferSourceFocus(true)}
                            onBlur={() => setReferSourceFocus(false)}
                            onChange={(e) => setReferSource(e.target.value)}
                          >
                            <option selected="" color="gray">* How did you come to know about us? Choose..</option>
                            <option>Facebook</option>
                            <option>Instagram</option>
                            <option>Google</option>
                            <option>Other</option>
                          </Input>
                        </InputGroup>
                      </Col>
                      {referSource !== null && referSource === "Other" &&
                        <Col md="6">
                          <InputGroup
                            className={
                              "input-lg" +
                              (referredByFocus ? " input-group-focus" : "")
                            }
                          >
                            <Input
                              placeholder="*Referred by"
                              type="text"
                              value={referredBy}
                              onFocus={() => setReferredByFocus(true)}
                              onBlur={() => setReferredByFocus(false)}
                              onChange={(e) => setReferredBy(e.target.value)}
                            ></Input>
                          </InputGroup>
                        </Col>
                      }
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="warn" onClick={(e) => { handleStartOver(e) }}><b>Start Over</b></Button>
                  </Col>
                  {cartRes !== null &&
                    <Col md="6" className="text-right">
                      <Button color="primary" onClick={(e) => { handleOnlineOrderNow(e) }}><b>Pay &#x20B9;{cartRes.totalSellPrice} Online</b><br />*With convenience charges</Button>
                       <Button color="danger" onClick={(e) => { handleOfflineOrderNow(e) }}><b>Direct Transfer &#x20B9;{cartRes.totalSellPrice}</b><br />*No convenience charges</Button>
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <DefaultFooter />
        <Modal
          isOpen={modalLarge}
          className="modal-lg"
          modalClassName="bd-example-modal-lg"
        >
          <ModalHeader>
            <h5 className="modal-title" id="myLargeModalLabel">
              Pay <b>&#x20B9;{orderTotal}</b> by scanning the QR Code / Transfer to account
            </h5>
            {/* <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModalClose()}
            >
              <span aria-hidden={true}>x</span>
            </button> */}
          </ModalHeader>
          <ModalBody className="text-center">
            <Row>
              <Col md="4">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4">QR Code</CardTitle>
                  <div>
                    <img alt="..." src={require("assets/img/sbi_qr.jpeg")}></img>
                  </div>
                </Card>
              </Col> 
              <Col md="8">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4">Account Details</CardTitle>
                  <Row>
                    <Col>
                      <div>
                        <img alt="..." src={require("assets/img/axis.png")}></img>
                      </div>
                    </Col>
                  </Row>
                  <Row className="text-left">
                    <Col>
                      <h5><b>Account Payee: </b>Sarjapur Outer Ring Road Bengali Association</h5>
                      <h5><b>Account No: </b>912010038909637</h5>
                      <h5><b>IFSC Code: </b>UTIB0002145</h5>
                      <br/>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="text-right">
            <Button color="primary" onClick={(e) => { handleUpdTxnDetails(e) }}><b>Update Transaction Details</b></Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modalLargeFinal}
          className="modal-lg"
          modalClassName="bd-example-modal-lg"
        >
          <ModalHeader>
            <h5 className="modal-title" id="myLargeModalLabel">
              Share transaction details for amount <b>&#x20B9;{orderTotal}</b>
            </h5>
            {/* <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModalClose()}
            >
              <span aria-hidden={true}>x</span>
            </button> */}
          </ModalHeader>
          <ModalBody className="text-center">
            <Row>
              <Col md="12">
                <Card border="primary" className="nav-tabs text-center" outline="true">
                  <CardTitle tag="h4">
                    Plaese pay manually and record Transaction Details for <b>&#x20B9;{orderTotal}</b>
                  </CardTitle>
                  <CardBody>
                    <InputGroup
                      className={
                        "input-lg" +
                        (offlineTxnIdFocus ? " input-group-focus" : "")
                      }
                    >

                      <Input
                        placeholder="Bank / UPI Transaction ID *"
                        type="text"
                        onFocus={() => setOfflineTxnIdFocus(true)}
                        onBlur={() => setOfflineTxnIdFocus(false)}
                        onChange={(e) => setOfflineTxnId(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "input-lg" +
                        (offlineBankNameFocus ? " input-group-focus" : "")
                      }
                    >

                      <Input
                        placeholder="Bank Name"
                        type="text"
                        onFocus={() => setOfflineBankNameFocus(true)}
                        onBlur={() => setOfflineBankNameFocus(false)}
                        onChange={(e) => setOfflineBankName(e.target.value)}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="text-right">
            <Button color="primary" onClick={(e) => { handleOfflineUpdDetails(e) }}><b>Submit</b></Button>
          </ModalFooter>

        </Modal>

      </div>
    </>
  );
}

export default OrderSummary;
