import React, {useState} from "react";
// reactstrap components
import {Col, Container, Row,} from "reactstrap";
import {APIRequest} from "utils/ApiRequest";
import {toast} from "react-toastify";

// core components

function ContactUs() {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [messageFocus, setMessageFocus] = React.useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  function handleSignupSubmit() {
    APIRequest(
      null,
      "/signUp",
      {
        name, email, message
      },
      () => {
        toast('Signed up successfully, please login!', { autoClose: 2000 });
      }
    );
  }


  return (
    <>
      <div
        className="section"

      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">Contact Us</h2>
            </Col>
          </Row>
          <br /><br />
          <Row>
            <Col md="8">
              <div class="info info-horizontal">
                <div class="description">
                  <h4 class="info-title"><i class="now-ui-icons location_pin"></i><b> Registered Address</b></h4>
                  <p class="description">
                    <b>
                      Sarjapur Outer Ring Road Bengali Association <br />
                      Flat# 108, Block#16, Suncity Apartments,<br />
                      Sarjapur Road, Bangalore<br />
                      Karnataka 560102, India
                    </b>
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div class="info info-horizontal">
                <div class="description">
                  <h4 class="info-title"><i class="now-ui-icons tech_mobile"></i><b> Give us a ring</b></h4>
                  <p class="description"><b>+91 84317 60322</b></p>
                </div>
              </div>
              <div class="info info-horizontal">
                <div class="description">
                  <h4 class="info-title"><i class="now-ui-icons ui-1_email-85"></i><b> Drop an email</b></h4>
                  <p class="description"><b>contact@sorrba.org</b></p>
                </div>
              </div>
            </Col>
          </Row>

        </Container>

      </div>
    </>
  );
}

export default ContactUs;
