import React from "react";
import {Link} from "react-router-dom";

// reactstrap components
import {Button, Col, Container, Row} from "reactstrap";

// core components

function AboutUs() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">Who we are?</h2>
            </Col>
            <Col className="text-justify" lg="8" md="12">
              <h5 className="description"><b>
                Sarjapur Outer Ring Road Bengali Association (SORRBA) was formed in 2008 by the Bengali families living around
                the junction of Sarjapur Road and Outer Ring Road in Bangalore. The main objective has been to bring the like-minded
                people in the neighborhood together and to celebrate various Bengali festivals and cherish the rich cultural heritage.

                <br /><br />
                SORRBA is a registered non-profit socio-cultural organization. Today SORRBA is a vibrant society of people from various cultural background.

                <br /><br />
                We at SORRBA, organize and participate in various activities throughout the year in keeping up with the objectives set out in our Memorandum of Association. These activities can broadly be classified in three categories –
                <ul>
                  <li>Socio-cultural,</li>
                  <li>Puja and </li>
                  <li>Philanthropic with an interactive platform for people of all walks of life irrespective of caste, creed or religion. This makes us one of the most renowned Socio-Cultural organization in the town.</li>
                </ul>

                <br />
                SORRBA celebrate Naba Barsha (Bengali New Year), Rabindra Jayanti (Tagore’s birth anniversary), Durga Puja, Lakshmi Puja, Kali Puja, Saraswati Puja, Sports Day with the overwhelming presence of members and neighborhood families.
              </b></h5>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button
                className="btn-round btn-white"
                color="danger"
                to="/sponsors"
                size="lg"
                tag={Link}
              >
                View Our Valued Puja Sponsors
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;
