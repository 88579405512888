/* eslint-disable consistent-return */
import { toast } from 'react-toastify';
import { baseUrl } from './urls';

export const APIRequest = (
  token,
  endpoint,
  data,
  callback,
  errorcallback
) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Session-Token': `${token}`,
    },
    body: JSON.stringify(data),
  };
  const url = `${baseUrl}${endpoint}`;
  console.log(url, data);
  fetch(url, requestOptions)
    .then(async (resp) => {
      const respData = await resp.json();
      const retData = { status: resp.status, data: respData };
      if (!resp.ok) {
        return Promise.reject(retData);
      }
      if (callback != null) {
        callback(retData);
      }
    })
    .catch((err) => {
      console.log(err);
      if (errorcallback != null) {
        errorcallback(err);
      } else {
        errorHandlerNew(err);
      }
    });
};

// Error handler

export const errorHandlerNew = (err) => {
  try {
    if (err.status) {
      console.log(err.status);
    }
    if (err.data.error === 'InsufficientAccess') {
      localStorage.removeItem('selectedPrds');
      localStorage.removeItem('totalCartPrice');
      localStorage.removeItem('user-info');
      localStorage.clear();
    }
    toast.error(err.data.desc);
  } catch (error) {
    console.log(error);
    toast.error('Seems some internal issue with your network, please check your network!');
  }
};
