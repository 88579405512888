import React, {useState} from "react";
import validator from 'validator'
import {v4 as uuid} from 'uuid';

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import {toast} from 'react-toastify';

import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {APIRequest} from "utils/ApiRequest";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";

function DailyPassPage() {
  const [membershipProducts, setMembershipProducts] = React.useState(null);
  const [selectedPrds, setSelectedPrds] = useState({});
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [modalLive, setModalLive] = React.useState(false);
  const [donationModal, setDonationModal] = React.useState(false);

  const [nameFocus, setNameFocus] = React.useState(false);
  const [amountFocus, setAmountFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [mobileFocus, setMobileFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [amount, setAmount] = React.useState(0);
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState(null);
  const [utasvCount, setUtasvCount] = useState(0);
  const [bsCount, setBSCount] = useState(0);


  const history = useNavigate();

  React.useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let userInfo = localStorage.getItem('user-info');
    if (userInfo != null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session != null) {
        setToken(userInfo.session);
        loadProducts(userInfo.session);
        let sPrd = localStorage.getItem('selectedPrds');
        if (sPrd != null) {
          setSelectedPrds(JSON.parse(sPrd));
        }
        let tcp = localStorage.getItem('totalCartPrice');
        setTotalCartPrice(tcp != null ? parseInt(tcp) : 0);
        setModalLive(false);
        loadUtsavCount(userInfo.session);
        loadBSCount(userInfo.session);
      } else {
        // setModalLive(true);
        loadProducts(null);
      }
    } else {
      // setModalLive(true);
      loadProducts(null);
    }

    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function loadProducts(token) {
    if (membershipProducts !== null) {
      return;
    }
    APIRequest(
      token,
      "/getUProducts",
      {},
      (res) => {
        setMembershipProducts(res.data.productsResponses["DAILY_PASS"]);
      }
    );
  }

  function loadUtsavCount(token) {
    APIRequest(
      token,
      "/getUtsavUserCount",
      {},
      (res) => {
        setUtasvCount(res.data.count);
      },
      () => {
        setUtasvCount(0);
      }
    );
  }

  function loadBSCount(token) {
    APIRequest(
      token,
      "/getBijayaSanmelaniCount",
      {},
      (res) => {
        setBSCount(res.data.count);
      },
      () => {
        setBSCount(0);
      }
    );
  }

  const handleProductDec = (productId, price, coPrice, code) => {
    let noOfAvailableTokens = utasvCount - bsCount;
    if (selectedPrds.hasOwnProperty(productId) && selectedPrds[productId].qty > 0) {
      let q = selectedPrds[productId].qty;
      if (q === 1) {
        delete selectedPrds[productId];
      } else {
        selectedPrds[productId].price = price * (q - 1);
        selectedPrds[productId].qty = q - 1;
      };

      if (q > 4 && code === "DPDY1") {
        let discountedPrice = price * 0.90 * (q - 1);
        selectedPrds[productId].price = discountedPrice;
      }

      if (q === 4 && code === "DPDY1") {
        let newPrice = price * (q - 1);
        selectedPrds[productId].price = newPrice;
        toast.warn("Benefit removed, effective price for three Dandiya Night Pass is: " + newPrice);
      }

      if (q > 1 && code === "DPKP" && noOfAvailableTokens > 0) {
        let newPrice = 0;
        if (q - 1 > noOfAvailableTokens)
          newPrice = (coPrice * noOfAvailableTokens) + (price * (q - noOfAvailableTokens - 1));
        else
          newPrice = coPrice * (q - 1);
        selectedPrds[productId].price = newPrice;
      }


      let totalPrice = 0;
      Object.keys(selectedPrds).map((k, i) => {
        totalPrice = totalPrice + selectedPrds[k].price;
      });

      setSelectedPrds(selectedPrds);
      setTotalCartPrice(totalPrice);
      localStorage.removeItem('selectedPrds');
      localStorage.setItem('selectedPrds', JSON.stringify(selectedPrds));
      localStorage.removeItem('totalCartPrice');
      localStorage.setItem('totalCartPrice', totalPrice);
    }
  };

  const handleProductInc = (productId, price, coPrice, code) => {
    let noOfAvailableTokens = utasvCount - bsCount;
    if (!selectedPrds.hasOwnProperty(productId)) {
      let prdDetails = {};
      prdDetails["qty"] = 1;
      prdDetails["price"] = price;
      if (code === "DPKP" && noOfAvailableTokens > 0) {
        prdDetails["price"] = coPrice;
      }
      prdDetails["code"] = code;
      selectedPrds[productId] = prdDetails;
    } else {
      let q = selectedPrds[productId].qty;
      selectedPrds[productId].qty = q + 1;
      selectedPrds[productId].price = selectedPrds[productId].price + price;

      if (q === 3 && code === "DPDY1") {
        let discountedPrice = price * 0.90 * (q + 1);
        selectedPrds[productId].price = discountedPrice;
        toast.success("10% benefit added new effective contribution for four Dandiya Night Pass is: " + discountedPrice);
      }

      if (q > 3 && code === "DPDY1") {
        let discountedPrice = price * 0.90 * (q + 1);
        selectedPrds[productId].price = discountedPrice;
      }

      if (code === "DPKP" && noOfAvailableTokens - q > 0) {
        let newPrice = price * (q + 1);
        if ((q + 1) > utasvCount)
          newPrice = (coPrice * utasvCount) + (price * (q - utasvCount + 1));
        else
          newPrice = coPrice * (q + 1);

        selectedPrds[productId].price = newPrice;
      }

    }

    let totalPrice = 0;
    Object.keys(selectedPrds).map((k, i) => {
      totalPrice = totalPrice + selectedPrds[k].price;
    });

    setSelectedPrds(selectedPrds);
    setTotalCartPrice(totalPrice);
    localStorage.removeItem('selectedPrds');
    localStorage.setItem('selectedPrds', JSON.stringify(selectedPrds));
    localStorage.removeItem('totalCartPrice');
    localStorage.setItem('totalCartPrice', totalPrice);
  }

  const handleProceedWContribute = () => {
    if (amount === undefined || amount === null || amount <= 0) {
      toast.error('Please add the amount to contribute!');
      return;
    }
    let prdDetails = {};
    prdDetails["qty"] = 1;
    prdDetails["price"] = amount;
    prdDetails["code"] = "DNP";
    selectedPrds[84] = prdDetails;

    let totalPrice = 0;
    Object.keys(selectedPrds).map((k, i) => {
      totalPrice = parseInt(totalPrice) + parseInt(selectedPrds[k].price);
    });

    setSelectedPrds(selectedPrds);
    setTotalCartPrice(totalPrice);
    localStorage.removeItem('selectedPrds');
    localStorage.setItem('selectedPrds', JSON.stringify(selectedPrds));
    localStorage.removeItem('totalCartPrice');
    localStorage.setItem('totalCartPrice', totalPrice);

    handleProceedToSponsorship();
  }

  const handleProceedToDonation = (e) => {
    if (selectedPrds === undefined || selectedPrds === null || Object.keys(selectedPrds).length === 0) {
      toast.error('Please select atleast one quantity befor proceeding!');
      return;
    }
    setDonationModal(true);
  }

  const handleProceedToSponsorship = (e) => {
    let noOfAvailableTokens = utasvCount - bsCount;
    if (selectedPrds === undefined || selectedPrds === null || Object.keys(selectedPrds).length === 0) {
      toast.error('Please select atleast one quantity befor proceeding!');
      return;
    }
    if (token === null) {
      setModalLive(true);
    } else {
      // history("/sponsorship");
      APIRequest(
        token,
        "/addToCart",
        JSON.parse('{"productQtys":' + JSON.stringify(selectedPrds) + ', "utsavMemberCount":' + noOfAvailableTokens + '}'),
        (res) => {
          history("/dpsummary");
        },
        (err) => {
          try {
            return toast.error(err.data.desc);
          } catch (error) {
            console.log(error);
            toast.error('Seems some internal issue with your network, please check your network!');
          }
        }
      );
    }
  }

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleStartOver = (e) => {
    localStorage.removeItem('selectedPrds');
    localStorage.removeItem('totalCartPrice');
    setTotalCartPrice(0);
    setSelectedPrds({});
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  function handleProceedSubmit() {
    if ((email === null || email === "")
      || (mobile === null || mobile === "")
      || (name === null || name === "")) {
      toast.error('All fields are Mandatory!');
    }
    else if (!isValidEmail(email)) {
      toast.error('Please enter a valid email!');
    } else {
      let iid = uuid();
      APIRequest(
        null,
        "/signUpAndLogin",
        {
          name, mobile, email, password, iid
        },
        (res) => {
          setToken(res.data.session);
          localStorage.setItem('user-info', JSON.stringify(res.data));
          setModalLive(false);
        }
      );
    }
  }

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  }

  function isValidEmail(email) {
    return validator.isEmail(email);
  }

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <Container>
            <Row className="text-center">
              <Col className="text-center" md="12">
                <h2 className="title">Offering / Daily Pass</h2>
              </Col>
              {/*<Col>*/}
              {/*  <Card>*/}
              {/*    <CardBody>*/}
              {/*      <blockquote className="blockquote blockquote-primary text-left">*/}
              {/*        <span>1. Only couples and single ladies allowed inside the Dandiya enclosure.</span><br />*/}
              {/*        <span>2. Participants should arrange for their own sticks.</span><br />*/}
              {/*        /!*<span>3. For groups of 4 and above a benefit of 10% is extended. The passes will be distributed at the venue.</span><br />*!/*/}
              {/*        <span>3. SORRBA Durga Pujo organisers reserves the right to restrict any participant from performing dandiya if any unethical practice or indecent behavior is noticed at the venue.</span><br />*/}
              {/*      </blockquote>*/}
              {/*    </CardBody>*/}
              {/*  </Card>*/}
              {/*</Col>*/}
            </Row>
            <Row>
              {membershipProducts != null && membershipProducts.map((mprd) => (
                mprd.available &&
                <Col md="6">
                  <Card border="primary" className="nav-tabs text-center" outline="true">
                    {/* <CardImg
                      alt="..."
                      src={require("assets/img/" + mprd.code + ".png")}
                      top
                    ></CardImg> */}
                    <CardTitle tag="h3"><b>{mprd.name}</b></CardTitle>
                    <CardSubtitle tag="h4">{mprd.desc}</CardSubtitle>
                    <CardBody>

                      <CardText>
                        <h5><b>Utsav Members: </b>&#x20B9;{mprd.coPrice}/person</h5>
                        <h5><b>Others: </b>&#x20B9;{mprd.price}/person</h5>
                        {/*<h5><b>&#x20B9;{mprd.price}</b></h5>*/}
                      </CardText>
                      <CardText>
                        <Badge
                          size="sm"
                          className="btn-round"
                          color="primary"
                          onClick={() => { handleProductDec(mprd.id, mprd.price, mprd.coPrice, mprd.code) }}
                        >
                          <b><i className="now-ui-icons ui-1_simple-delete"></i></b>
                        </Badge>
                        <span> &nbsp; {selectedPrds != null && selectedPrds[mprd.id] != null ? selectedPrds[mprd.id].qty : 0} &nbsp;</span>
                        <Badge
                          size="sm"
                          className="btn-round"
                          color="primary"
                          onClick={() => { handleProductInc(mprd.id, mprd.price, mprd.coPrice, mprd.code) }}
                        >
                          <i className="now-ui-icons ui-1_simple-add"></i>
                        </Badge>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              ))}

            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="8" className="text-left">
                        <CardText><h5><b>Total Amount: &#x20B9;{totalCartPrice}</b></h5></CardText>
                      </Col>
                      <Col md="4" className="text-right">
                        <Button color="warn" onClick={(e) => { handleStartOver(e) }}><b>Clear Selection</b></Button>
                        <Button color="primary" onClick={handleProceedToDonation}><b>Proceed</b></Button>
                      </Col>
                    </Row>


                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Modal toggle={() => {
              setModalLive(false);
            }
            } isOpen={modalLive}>
              <div className="modal-header">
                <h5 className="modal-title" id="membershipWarnModal">
                  Register to Proceed
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={() => {
                    setModalLive(false);
                  }}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <ModalBody>
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border" + (nameFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          placeholder="* Full Name"
                          type="text"
                          onFocus={() => setNameFocus(true)}
                          onBlur={() => setNameFocus(false)}
                          onChange={handleNameChange}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border" + (emailFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          placeholder="* Email"
                          type="text"
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          onChange={handleEmailChange}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border" + (mobileFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          placeholder="* Mobile"
                          type="text"
                          onFocus={() => setMobileFocus(true)}
                          onBlur={() => setMobileFocus(false)}
                          onChange={handleMobileChange}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border" + (passwordFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          placeholder="* Password"
                          type="password"
                          onFocus={() => setPasswordFocus(true)}
                          onBlur={() => setPasswordFocus(false)}
                          onChange={handlePasswordChange}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        color="danger"
                        onClick={handleProceedSubmit}
                        size="lg"
                      >
                        Proceed
                      </Button>
                      <Row>
                        <Col>
                          <Button
                            className="btn-link text-left"
                            color="primary"
                            to="/login"
                            size="lg"
                            tag={Link}
                          >
                            Already registered, Login Here!
                          </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Form>
                </Card>
              </ModalBody>
            </Modal>

            <Modal isOpen={donationModal}>
              <div className="modal-header">
                <h5 className="modal-title" id="membershipWarnModal">
                  Your generous contribution of any additional amount for Puja Mantap or Community Bhog, will be highly valued for SORRBA
                </h5>
              </div>
              <ModalBody>
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border" + (nameFocus ? " input-group-focus" : "")
                        }
                      >
                        <Input
                          placeholder="* Amount to Contribute"
                          type="text"
                          onFocus={() => setAmountFocus(true)}
                          onBlur={() => setAmountFocus(false)}
                          onChange={handleAmountChange}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        color="danger"
                        onClick={handleProceedWContribute}
                        size="lg"
                      >
                        Proceed with Contribution
                      </Button>
                      <Row>
                        <Col>
                          <Button
                            className="btn-link text-left"
                            color="primary"
                            onClick={handleProceedToSponsorship}
                            size="lg"
                          >
                            <b>Skip Contribution</b>
                          </Button>
                        </Col>
                      </Row>
                    </CardFooter>
                  </Form>
                </Card>
              </ModalBody>
            </Modal>

          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default DailyPassPage;
