import React from "react";

// reactstrap components
import {Col, Container, Row,} from "reactstrap";

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";

function TermAndConditionPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <Container className="text-center">
            <Row className="justify-content-md-center">
              <Col className="text-center" lg="8" md="12">
                <h2 className="title">Terms and Conditions</h2>
              </Col>

              <Col className="text-justify" lg="8" md="12">
                <h4>Introduction</h4>
                <h5 className="description">
                  <b>
                    Sarjapura Outer Ring Road Bengali Association’s (hereinafter referred to as SORRBA) most valuable asset is the wellbeing
                    and reputation of the members, vendors and stakeholders, and believes in developing and protecting this asset. <br /><br />
                    The Privacy Policy explains our policy regarding the collection, use, disclosure and transfer of your data by SORRBA, offline
                    and other services including but not limited to delivery of Data and content via any mobile or internet connected device or
                    otherwise (collectively the "Services").<br /><br />
                    By accessing the organisation website or this application or otherwise using the services, you consent to collection,
                    storage, and use of the data that you provide (including any changes thereto as provided by you) for any of the services
                    that SORRBA offers. The Company respects the privacy of the users of the services and is committed to protect it in all respects,
                    giving it utmost priority.  Before sharing any information representing any third party, it is ideal and recommended to obtain the
                    consent of such third party for disclosure and further processing of such information in accordance with this policy.<br /><br />
                  </b>
                </h5>

                <h4>Delivery policy</h4>
                <h5 className="description">
                  <b>
                    We ensure that all subscriptions made online will be delivered to you within the specified dates. Once the payment is cleared,
                    an email is automatically sent to your email address acknowledging the payment and the services to be rendered against the same.
                    We suggest you to save the mail communication you received in your mail box for future reference.<br /><br />
                  </b>
                </h5>

                <h4>Cancellation policy</h4>
                <h5 className="description">
                  <b>
                    Cancellation of Subscription may be considered only in case of purchases made by the members by mistake. However, the Organisation
                    may at its sole discretion consider the cancellation in other cases and may refund in part or full.<br /><br />
                  </b>
                </h5>

                <h4>Refund policy</h4>
                <h5 className="description">
                  <b>
                    Subscription once purchased cannot be cancelled. Services once rendered shall not be accepted back by the Organisation. However,
                    the Organisation may at its sole discretion consider accepting return of a service and grant of part or full refund in such circumstances.<br /><br />
                  </b>
                </h5>

                <h4>Privacy policy</h4>
                <h5 className="description">
                  <b>
                    Protecting your privacy is very important to us. As part of the normal operation of our services, your basic information (name, address,
                    e-mail and phone numbers) is collected by us for internal record purpose. We do use your contact information to send you services based
                    on your orders and your interests and we also use your name and address for raising bills and invoices.<br /><br />
                  </b>
                </h5>

                <h4>Data Collection</h4>
                <h5 className="description">
                  <b>
                    <ol>
                      <li>
                        SORRBA may collect, store and use the following kinds of Personal Information:
                        <ol type="a">
                          <li>Online and offline data submission, by filing forms online and providing name, email ID, contacting by SMS, chat or mail and providing your contact information</li>
                          <li>During Events, program or other socio-cultural events</li>
                          <li>Sharing of information such as name, email, mobile and other information with SORRBA or any of the vendors</li>
                          <li>At the time of service usage, sharing of Admin ID, contact number, etc.</li>
                        </ol>
                      </li>
                      <br />
                      <li>
                        Cookies <br />
                        Our Sites use cookies and other technologies to function effectively. These technologies record Data about your use of our Sites, including:
                        <ol type="a">
                          <li>Browser and device data, such as IP address, device type, operating system and Internet browser type, screen resolution, operating system name
                            and version, device manufacturer and model, and language/version of the Websites you are visiting</li>
                          <li>Usage data, such as time spent on the Website, pages visited, links clicked, language preferences, and the pages that led or referred you to our Website</li>
                        </ol>

                      </li>
                    </ol>
                  </b>
                </h5>

                <h4>Use of collected information</h4>
                <h5 className="description">
                  <b>
                    <ol>
                      <li>
                        Advertising and Marketing <br /><br />
                        SORRBA uses the personal data to provide you with information material about events and services tailored to your individual interests, invite you to
                        participate in events and surveys and to follow up with requested products and services.
                      </li>
                      <br />
                      <li>
                        Member Support <br />
                        SORRBA may use the data collected to identify you and provide a personalized support across different touch points, where you choose to contact us.
                        To the extent permissible under applicable law, we use your information to:
                        <ol type="a">
                          <li>Our service providers and agents/vendors (including their sub-contractors) or third parties which process information on our behalf
                            (e.g. internet service and platform providers, payment processing providers and those organizations we engage to help us send communications to you) so
                            that they may help us to provide you with the applications, products, services and information you have requested or which we believe is of interest to you</li>
                          <li>SORRBA affiliates that may help us to provide you with the products, services and information you have requested or which we believe is of interest to you</li>
                          <li>Third parties used to facilitate payment transactions</li>
                          <li>Regulators to meet our legal and regulatory obligations; and</li>
                          <li>Law enforcement agencies so that they may detect or prevent crime or prosecute offenders</li>

                        </ol>
                        However, SORRBA will not, without your express consent, supply your information to any third party for the purpose of their or any other third party’s direct marketing purposes.
                      </li>
                    </ol>
                  </b>
                </h5>

                <h4>Disclosing Collected Information</h4>
                <h5 className="description">
                  <b>
                    SORRBA may share your information with:
                    <ol>
                      <li>Our service providers and agents/vendors (including their sub-contractors) or third parties which process information on our behalf (e.g. internet service and platform providers, payment processing providers and those organizations we engage to help us send communications to you) so that they may help us to provide you with the applications, products, services and information you have requested or which we believe is of interest to you;</li>
                      <li>SORRBA affiliates that may help us to provide you with the products, services and information you have requested or which we believe is of interest to you;</li>
                      <li>Third parties used to facilitate payment transactions</li>
                      <li>Regulators to meet our legal and regulatory obligations; and</li>
                      <li>Law enforcement agencies so that they may detect or prevent crime or prosecute offenders.</li>
                    </ol>
                    We may share non-personally identifiable information about the use of our website, products and/or services publicly or with third parties. Under no circumstances, SORRBA will share any information with any third party for any purpose other than the stated ones, without your express consent.
                  </b>
                </h5>

                <h4>Security of Data</h4>
                <h5 className="description">
                  <b>
                    <ol>
                      <li>SORRBA will take required technical and organizational precautions to prevent the loss, misuse or manipulation of the information shared.</li>
                      <li>SORRBA will store all the information so collected, on our secure password protected servers.</li>
                      <li>You acknowledge the fact that data transmitted through internet is naturally prone to insecurity and SORRBA cannot guarantee the security of data shared over the internet.</li>
                    </ol>
                  </b>
                </h5>

                <h4>Your Rights and Choices</h4>
                <h5 className="description">
                  <b>
                    Privacy  and Data Protection regulations such as GDPR and CCPA grant you rights that you can exercise over your personal data that the Organization collects and manages. Every SORRBA member shall have the same rights over their personal data.
                    <ol>
                      <li>Right to Confirmation and Access: Right to know how personal data is being used by SORRBA.</li>
                      <li>Right to Correction: Right to access, update or correct Data residing with SORRBA</li>
                      <li>Right to Data Portability: Right to receive your personal data in a structured, commonly used and machine-readable format. Also, the right to transmit this data to another vendor/company of your choice without hindrance from SORRBA</li>
                      <li>Right to Be Forgotten: Right to restrict or prevent continuing disclosure of Data to SORRBA</li>
                      <li>Right to withdrawal of consent: SORRBA will provide you with an opportunity to exit or withdraw consent from processing your data further.</li>
                    </ol>
                  </b>
                </h5>

                <h4>General Information</h4>
                <h5 className="description">
                  <b>
                    <ol>
                      <li>Consent</li><br />
                      All collected data is subject to your explicit content, made available on or after the date of this Privacy Notice. You can also withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing SORRBA conducted prior to your withdrawal, nor will it affect processing of your Data conducted in reliance on lawful processing grounds other than consent.
                      <br /><br /><br />
                      <li>Retention of Data</li><br />
                      When SORRBA has no ongoing legitimate business need to process your Personal Information, SORRBA will either delete or anonymize it or, if this is not possible, then SORRBA will securely store your Personal Information and isolate it from any further processing until deletion is possible.
                      <br /><br />
                      When SORRBA has no ongoing legitimate business need to process your Personal Information, SORRBA will either securely dispose your data or archive it with anonymization if it is required to support future communications
                      <br /><br />
                      SORRBA is not registered as a Charitable Trust with the Income Tax Department and deduction U/s 80G will not be available to the members against any membership fees / subscription payment

                    </ol>
                  </b>
                </h5>


                <h4>Grievance Redressal</h4>
                <h5 className="description">
                  <b>
                    To exercise any of the rights or for sharing any grievance or queries, please write us a mail to ec@sorrba.org. SORRBA team shall strive to resolve any customer queries or grievances at the earliest possible timeframe, in an amicable manner.
                  </b>
                </h5>



                <h4>Dispute Resolution</h4>
                <h5 className="description">
                  <b>
                    Neither party shall institute a proceeding in any court or administrative agency to resolve a dispute between the parties before that party has sought to resolve the dispute through direct negotiation with the other party. If the dispute is not resolved within two weeks after a demand for direct negotiation, the parties shall attempt to resolve the dispute through court of Law of Bangalore
                  </b>
                </h5>


              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="section section-team text-center">
          <Container>
            <h2 className="title">Here is our team</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/avatar.jpg")}
                    ></img>
                    <h4 className="title">Romina Hadid</h4>
                    <p className="category text-info">Model</p>
                    <p className="description">
                      You can write here details about one of your team members.
                      You can give more details about what they do. Feel free to
                      add some{" "}
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        links
                      </a>{" "}
                      for people to be able to follow them outside the site.
                    </p>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-instagram"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook-square"></i>
                    </Button>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/ryan.jpg")}
                    ></img>
                    <h4 className="title">Ryan Tompson</h4>
                    <p className="category text-info">Designer</p>
                    <p className="description">
                      You can write here details about one of your team members.
                      You can give more details about what they do. Feel free to
                      add some{" "}
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        links
                      </a>{" "}
                      for people to be able to follow them outside the site.
                    </p>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-linkedin"></i>
                    </Button>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/eva.jpg")}
                    ></img>
                    <h4 className="title">Eva Jenner</h4>
                    <p className="category text-info">Fashion</p>
                    <p className="description">
                      You can write here details about one of your team members.
                      You can give more details about what they do. Feel free to
                      add some{" "}
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        links
                      </a>{" "}
                      for people to be able to follow them outside the site.
                    </p>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-google-plus"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-youtube"></i>
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section section-contact-us text-center">
          <Container>
            <h2 className="title">Want to work with us?</h2>
            <p className="description">Your project is very important to us.</p>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <InputGroup
                  className={
                    "input-lg" + (firstFocus ? " input-group-focus" : "")
                  }
                >

                  <Input
                    placeholder="First Name..."
                    type="text"
                    onFocus={() => setFirstFocus(true)}
                    onBlur={() => setFirstFocus(false)}
                  ></Input>
                </InputGroup>
                <InputGroup
                  className={
                    "input-lg" + (lastFocus ? " input-group-focus" : "")
                  }
                >
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email..."
                    type="text"
                    onFocus={() => setLastFocus(true)}
                    onBlur={() => setLastFocus(false)}
                  ></Input>
                </InputGroup>
                <div className="textarea-container">
                  <Input
                    cols="80"
                    name="name"
                    placeholder="Type a message..."
                    rows="4"
                    type="textarea"
                  ></Input>
                </div>
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="lg"
                  >
                    Send Message
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        <DefaultFooter />
      </div>
    </>
  );
}

export default TermAndConditionPage;
