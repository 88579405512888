import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import validator from 'validator'

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import {APIRequest} from "utils/ApiRequest";
import {toast} from "react-toastify";

function SignUpPage() {

  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [mobileFocus, setMobileFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [password, setPassword] = useState(null);

  const history = useNavigate();

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let userInfo = localStorage.getItem('user-info');
    if (userInfo != null) {
      history("/index");
    }

    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  function handleSignupSubmit() {
    if ((email === null || email === "")
      || (password === null || password === "")
      || (mobile === null || mobile === "")
      || (name === null || name === "")) {
      toast.error('All fields are Mandatory!');
    }
    else if (!isValidEmail(email)) {
      toast.error('Email is invalid!');
    } else {
      APIRequest(
        null,
        "/signUp",
        {
          name, mobile, email, password
        },
        () => {
          toast.success('We have sent verfication email to your email address, please validate before login!');
          history("/login");
        }
      );
    }
  }

  function isValidEmail(email) {
    return validator.isEmail(email);
  }

  return (
    <>
      <IndexNavbar />
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg22.png") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
              <Card className="card-signup" data-background-color="black">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <CardTitle className="title-up" tag="h3">
                      Sign Up
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border" + (nameFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="* Full Name"
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                        onChange={handleNameChange}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border" + (emailFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="* Email"
                        type="text"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={handleEmailChange}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border" + (mobileFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="* Mobile"
                        type="text"
                        onFocus={() => setMobileFocus(true)}
                        onBlur={() => setMobileFocus(false)}
                        onChange={handleMobileChange}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border" + (passwordFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="* Password"
                        type="password"
                        onFocus={() => setPasswordFocus(true)}
                        onBlur={() => setPasswordFocus(false)}
                        onChange={handlePasswordChange}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      className="btn-round"
                      color="danger"
                      onClick={handleSignupSubmit}
                      size="lg"
                    >
                      Create Account
                    </Button>
                    <Row>
                      <Col>
                        <Button
                          className="btn-link text-left"
                          color="primary"
                          to="/login"
                          size="lg"
                          tag={Link}
                        >
                          Back to Login
                        </Button>
                      </Col>
                    </Row>

                  </CardFooter>
                </Form>
              </Card>
            </Row>
            <div className="col text-center">

            </div>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default SignUpPage;
