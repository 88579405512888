import React from "react";
import ReactDOM from "react-dom";
import {HashRouter, Route, Routes} from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

import Index from "views/Index.js";
import LoginPage from "views/pages/LoginPage";
import ProfilePage from "views/pages/ProfilePage";
import SignUpPage from "views/pages/SignupPage";
import CSRPage from "views/pages/CSRPage";
import TermAndConditionPage from "views/pages/TermAndConditionPage";
import CartPage from "views/pages/CartPage";
import OrderSummary from "views/pages/OrderSummary";
import MembershipPage from "views/pages/MembershipPage";
import SponsorshipPage from "views/pages/SponsorshipPage";
import SponsoredByPage from "views/pages/SponsoredByPage";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminPage from "views/pages/AdminPage";
import ForgotPasswordPage from "views/pages/ForgotPassword";
import ChangePasswordPage from "views/pages/ChangePassword";
import ToshaniPage from "views/pages/ToshaniPage";
import CorporatePage from "views/pages/CorporatePage";
import Durgotsav2022Page from "views/pages/Durgotsav2022Page";
import DailyPassPage from "views/pages/DailyPassPage";
import OrderDPSummary from "views/pages/OrderDPSummary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<React.StrictMode>
    <HashRouter>
        <Routes>
            <Route path="/index" Component={Index}/>
            <Route
                path="/responsibitity"
                Component={CSRPage}
            />
            <Route
                path="/profile"
                Component={ProfilePage}
            />
            <Route
                path="/forgotPasswd"
                Component={ForgotPasswordPage}
            />
            <Route
                path="/changePasswd"
                Component={ChangePasswordPage}
            />
            <Route
                path="/login"
                Component={LoginPage}
            />
            <Route
                path="/signup"
                Component={SignUpPage}
            />
            <Route
                path="/tnc"
                Component={TermAndConditionPage}
            />
            <Route
                path="/cart"
                Component={CartPage}
            />
            <Route
                path="/membership"
                Component={MembershipPage}
            />
            <Route
                path="/sponsorship"
                Component={SponsorshipPage}
            />
            <Route
                path="/summary"
                Component={OrderSummary}
            />
            <Route
                path="/dpsummary"
                Component={OrderDPSummary}
            />
            <Route
                path="/sponsors"
                Component={SponsoredByPage}
            />
            <Route
                path="/admin"
                Component={AdminPage}
            />
            <Route
                path="/toshani"
                Component={ToshaniPage}
            />
            <Route
                path="/corporate"
                Component={CorporatePage}
            />
            <Route
                path="/2023"
                Component={Durgotsav2022Page}
            />
            <Route
                path="/dmPass"
                Component={DailyPassPage}
            />

            <Route path="*" Component={Index}/>
        </Routes>
        <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            theme="dark"
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    </HashRouter>
</React.StrictMode>);
