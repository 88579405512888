import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {v4 as uuid} from 'uuid';

// reactstrap components
import {Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Input, InputGroup,} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import {APIRequest} from "utils/ApiRequest";
import {toast} from 'react-toastify';

function ChangePasswordPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [email, setEmail] = useState(null);
  const [oldPasswdFocus, setOldPassewdFocus] = React.useState(false);
  const [newPasswdFocus, setNewPasswdFocus] = React.useState(false);
  const history = useNavigate();
  const [oldPasswd, setOldPasswd] = useState(null);
  const [password, setPassword] = useState(null);

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let userInfo = localStorage.getItem('user-info');
    if (userInfo != null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session != null) {
        history("/index");
      }
    }

    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleOldPassWordChange = (e) => {
    setOldPasswd(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value.trim());
  };

  function handleChangePasswordSubmit() {
    if ((email === null || email === "") || (oldPasswd === null || oldPasswd === "") || (password === null || password === "")) {
      toast.error('All fields are Mandatory!');
    }
    else if (!isValidEmail(email)) {
      toast.error('Email is invalid!');
    } else {
      let iid = uuid();
      APIRequest(
        null,
        "/changePasswd",
        {
          email, tempPasswd: oldPasswd, newPasswd: password
        },
        (res) => {
          toast.success("Your password updated successfully, Please login now!");
          history("/login");
        }
      );
    }
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  return (
    <>
      <IndexNavbar />
      <div className="page-header clear-filter" filter-color="orange">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/bg22.png") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <div className="logo-container">
                      <img
                        alt="..."
                        src={require("assets/img/now-logo.png")}
                      ></img>
                    </div>
                  </CardHeader>
                  <CardBody>
                  <InputGroup
                      className={
                        "input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                    <Input
                        placeholder="email"
                        type="text"
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        onChange={handleEmailChange}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "input-lg" +
                        (oldPasswdFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="Temporary Code / Old Password"
                        type="password"
                        onFocus={() => setOldPassewdFocus(true)}
                        onBlur={() => setOldPassewdFocus(false)}
                        onChange={handleOldPassWordChange}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "input-lg" +
                        (newPasswdFocus ? " input-group-focus" : "")
                      }
                    >
                      <Input
                        placeholder="Password"
                        type="password"
                        onFocus={() => setNewPasswdFocus(true)}
                        onBlur={() => setNewPasswdFocus(false)}
                        onChange={handlePasswordChange}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="danger"
                      onClick={handleChangePasswordSubmit}
                      size="lg"
                    >
                      <b>Change Password</b>
                    </Button>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default ChangePasswordPage;
