import React, { useState } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import { APIRequest } from "utils/ApiRequest";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isMembershipLive, setMembersipLive] = useState(false);
  const [isDailyPassLive, setDailyPassLive] = useState(false);

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);

    let userInfo = localStorage.getItem('user-info');
    if (userInfo != null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session !== null) {
        setLoggedIn(true);
        setIsAdmin(userInfo.admin);
        loadDailyPassAviability(userInfo.session);
        loadMembershipAviability(userInfo.session);
      } else {
        localStorage.clear()
      }
    } else {
      localStorage.clear();
    }
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  }, []);

  function handleLogout() {
    localStorage.clear();
  }

  async function loadMembershipAviability(token) {
    APIRequest(
      token,
      "/isMembershipLive",
      {},
      (res) => {
        setMembersipLive(res.data.available)
      },
    );
  }

  async function loadDailyPassAviability(token) {
    APIRequest(
      token,
      "/isDailyPassLive",
      {},
      (res) => {
        setDailyPassLive(res.data.available)
      },
    );
  }

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="danger">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand to="/index" tag={Link}>
              <i className="now-ui-icons shopping_shop mr-1"></i>
              <b>SORRBA</b>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  outline
                  color="info"
                  href="#pablo"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i class="now-ui-icons files_paper"></i>
                  <p>Menu</p>
                </DropdownToggle>
                <DropdownMenu>
                  {isAdmin &&
                    <DropdownItem to="/admin" tag={Link}>
                      Admin Page
                    </DropdownItem>
                  }
                  <DropdownItem to="/index" tag={Link}>
                    Home
                  </DropdownItem>
                  {!loggedIn &&
                    <DropdownItem to="/login" tag={Link}>
                      Login
                    </DropdownItem>
                  }
                  {loggedIn &&
                    <DropdownItem to="/profile" tag={Link}>
                      My Profile
                    </DropdownItem>
                  }
                  <DropdownItem to="/sponsors" tag={Link}>
                    Our Pujo Sponsors
                  </DropdownItem>
                  <DropdownItem to="/t22" tag={Link}>
                    Toshani 2022
                  </DropdownItem>
                  <DropdownItem to="/tnc" tag={Link}>
                    Terms and Conditions
                  </DropdownItem>
                  <DropdownItem to="/responsibitity" tag={Link}>
                    Our Social Responsibility
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {/* <NavItem>
                <NavLink
                  to="/2023"
                  id="upgrade-to-pro"
                  tag={Link}
                >
                  <i className="now-ui-icons objects_diamond"></i>
                  <b><p>Durgotsab 2023</p></b>
                </NavLink>
              </NavItem> */}

              {
                <NavItem>
                  <Button
                    className="nav-link btn-neutral"
                    color="danger"
                    to="/dmPass"
                    id="upgrade-to-pro"
                    tag={Link}
                  >
                    <i className="now-ui-icons loader_refresh"></i>
                    <b><p>&nbsp;Coupons | Offering</p></b>
                  </Button>
                </NavItem>
              }

              {/* {loggedIn &&
                <NavItem>
                  <Button
                    className="nav-link btn-neutral"
                    color="danger"
                    to="/profile"
                    id="upgrade-to-pro"
                    tag={Link}
                  >
                    <i className="now-ui-icons business_money-coins"></i>
                    <b><p>&nbsp;My Wallet</p></b>
                  </Button>
                </NavItem>
              } */}
              {/* <NavItem>
                <Button
                  className="nav-link btn-neutral"
                  color="error"
                  to="/t22"
                  id="upgrade-to-pro"
                  tag={Link}
                >
                  <i className="now-ui-icons education_paper"></i>
                  <b><p>&nbsp;Toshani 2022</p></b>
                </Button>
              </NavItem> */}

              {!loggedIn &&
                <NavItem>
                  <Button
                    className="nav-link btn-neutral"
                    color="danger"
                    to="/signup"
                    id="upgrade-to-pro"
                    tag={Link}
                  >
                    <i className="now-ui-icons arrows-1_share-66 mr-1"></i>
                    <b><p>Create Account</p></b>
                  </Button>
                </NavItem>
              }

              {isMembershipLive && loggedIn &&
                <NavItem>
                  <Button
                    className="nav-link btn-neutral"
                    color="danger"
                    to="/membership"
                    id="upgrade-to-pro"
                    tag={Link}
                  >
                    <i className="now-ui-icons arrows-1_share-66 mr-1"></i>
                    <b><p>Membership / Sponsorship</p></b>
                  </Button>
                </NavItem>
              }

              {/*<NavItem>*/}
              {/*  <NavLink*/}
              {/*    href="https://www.youtube.com/channel/UCl6VU1xr1Fx_Np4_QSYhdVQ"*/}
              {/*    target="_blank"*/}
              {/*    id="youtube-tooltip"*/}
              {/*  >*/}
              {/*    <i className="fab fa-youtube"></i>*/}
              {/*    <p className="d-lg-none d-xl-none">Youtube</p>*/}
              {/*  </NavLink>*/}
              {/*  <UncontrolledTooltip target="#youtube-tooltip">``*/}
              {/*    Subscibe us on Youtube*/}
              {/*  </UncontrolledTooltip>*/}
              {/*</NavItem>*/}
              {/*<NavItem>*/}
              {/*  <NavLink*/}
              {/*    href="https://www.facebook.com/sorrba.bangalore"*/}
              {/*    target="_blank"*/}
              {/*    id="facebook-tooltip"*/}
              {/*  >*/}
              {/*    <i className="fab fa-facebook-square"></i>*/}
              {/*    <p className="d-lg-none d-xl-none">Facebook</p>*/}
              {/*  </NavLink>*/}
              {/*  <UncontrolledTooltip target="#facebook-tooltip">*/}
              {/*    Like us on Facebook*/}
              {/*  </UncontrolledTooltip>*/}
              {/*</NavItem>*/}
              {loggedIn &&
                <NavItem>
                  <NavLink
                    onClick={handleLogout}
                    to="/login"
                    tag={Link}
                    id="facebook-tooltip"
                  >
                    <i className="now-ui-icons media-1_button-power mr-1"></i>
                    <p className="d-lg-none d-xl-none">Logout</p>
                  </NavLink>
                  <UncontrolledTooltip target="#facebook-tooltip">
                    Logout
                  </UncontrolledTooltip>
                </NavItem>
              }
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
