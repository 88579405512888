import React from "react";

// reactstrap components
import {Carousel, CarouselIndicators, CarouselItem, Col, Container, Row,} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/img/bg22.png"),
    altText: "Chandrabindu, 2019",
    caption: "Chandrabindu, 2019",
  },
  {
    src: require("assets/img/bg.png"),
    altText: "Anupam Roy, 2018",
    caption: "Anupam Roy, 2018",
  },
  {
    src: require("assets/img/gallery/1.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/3.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/4.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/5.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/6.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/7.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/8.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/9.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/10.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/11.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/12.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/13.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/14.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/15.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/16.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/17.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/18.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/19.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/20.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/21.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/22.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/23.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/24.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/25.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/26.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/27.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/28.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/29.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/30.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/31.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/32.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/33.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/34.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/35.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/36.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/37.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/38.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/39.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/40.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/41.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/42.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/43.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/44.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/45.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/46.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/47.jpeg"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/gallery/48.jpeg"),
    altText: "",
    caption: "",
  }
];

function GallarySection() {
  const [activeIndex, setActiveIndex] = React.useState(randomNumberInRange(1,48));
  const [animating, setAnimating] = React.useState(false);

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section"
        data-background-color="shade"
        id="carousel">
        <Container>
          <Row className="justify-content-center">
            <Col className="text-center" lg="8" md="12">
              <h2 className="title">Our Gallery</h2>
            </Col>
            <Col md="12">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                {/*<CarouselIndicators*/}
                {/*  items={items}*/}
                {/*  activeIndex={activeIndex}*/}
                {/*  onClickHandler={goToIndex}*/}
                {/*/>*/}
                {items.map((item) => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <img src={item.src} alt={item.altText} />
                      <div className="carousel-caption d-none d-md-block">
                        <h5>{item.caption}</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default GallarySection;
