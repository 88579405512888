import React, {useState} from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import {toast} from 'react-toastify';

// core components
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar";
import {APIRequest} from "utils/ApiRequest";
import {useNavigate} from "react-router";

function MembersipPage() {
  const [membershipProducts, setMembershipProducts] = React.useState(null);
  const [selectedPrds, setSelectedPrds] = useState({});
  const [totalCartPrice, setTotalCartPrice] = useState(0);
  const [modalLarge, setModalLarge] = React.useState(false);
  const [modalLive, setModalLive] = React.useState(false);

  const history = useNavigate();

  React.useEffect(() => {
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    let userInfo = localStorage.getItem('user-info');
    if (userInfo != null) {
      userInfo = JSON.parse(userInfo);
      if (userInfo.session != null) {
        loadProducts(userInfo.session);
        let sPrd = localStorage.getItem('selectedPrds');
        if (sPrd != null) {
          setSelectedPrds(JSON.parse(sPrd));
        }
        let tcp = localStorage.getItem('totalCartPrice');
        setTotalCartPrice(tcp != null ? parseInt(tcp) : 0);

      } else {
        history("/login")
      }
    } else {
      history("/login")
    }

    return function cleanup() {
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function loadProducts(token) {
    APIRequest(
      token,
      "/getProducts",
      {},
      (res) => {
        setMembershipProducts(res.data.productsResponses["MEMBERSHIP"]);
      }
    );
  }

  const handleProductDec = (productId, price, code) => {
    if (selectedPrds.hasOwnProperty(productId) && selectedPrds[productId].qty > 0) {
      let q = selectedPrds[productId].qty;
      if (q === 1) {
        delete selectedPrds[productId];
      } else {
        selectedPrds[productId].qty = q - 1;
      };

      if (q > 4 && code === "MUTV") {
        let discountedPrice = price * 0.85 * (q - 1);
        selectedPrds[productId].price = discountedPrice;
      }

      if (q === 4 && code === "MUTV") {
        let discountedPrice = price * 0.95 * (q - 1);
        selectedPrds[productId].price = discountedPrice;
        toast.warn("Benifit reduced 10% to 5%, effective price for three UTSAV membership plan is: " + discountedPrice);
      }

      if (q === 3 && code === "MUTV") {
        let newPrice = price * (q - 1);
        selectedPrds[productId].price = newPrice;
        toast.warn("Benefit removed, effective price for two UTSAV membership plan is: " + newPrice);
      }

      let totalPrice = 0;
      Object.keys(selectedPrds).map((k, i) => {
        totalPrice = totalPrice + selectedPrds[k].price;
      });

      setSelectedPrds(selectedPrds);
      setTotalCartPrice(totalPrice);
      localStorage.removeItem('selectedPrds');
      localStorage.setItem('selectedPrds', JSON.stringify(selectedPrds));
      localStorage.removeItem('totalCartPrice');
      localStorage.setItem('totalCartPrice', totalPrice);
    }
  };

  const handleProductInc = (productId, price, code) => {
    if (!selectedPrds.hasOwnProperty(productId)) {
      let prdDetails = {};
      prdDetails["qty"] = 1;
      prdDetails["price"] = price;
      prdDetails["code"] = code;
      selectedPrds[productId] = prdDetails;
    } else {
      let q = selectedPrds[productId].qty;
      selectedPrds[productId].qty = q + 1;
      selectedPrds[productId].price = selectedPrds[productId].price + price;

      if (q === 2 && code === "MUTV") {
        let discountedPrice = price * 0.95 * (q + 1);
        selectedPrds[productId].price = discountedPrice;
        toast.success("5% benefit added new effective contribution for three UTSAV membership plan is: " + discountedPrice);
      }

      if (q === 3 && code === "MUTV") {
        let discountedPrice = price * 0.90 * (q + 1);
        selectedPrds[productId].price = discountedPrice;
        toast.success("10% benefit added new effective contribution for four UTSAV membership plan is: " + discountedPrice);
      }

      if (q > 3 && code === "MUTV") {
        let discountedPrice = price * 0.90 * (q + 1);
        selectedPrds[productId].price = discountedPrice;
      }

    }



    let totalPrice = 0;
    Object.keys(selectedPrds).map((k, i) => {
      totalPrice = totalPrice + selectedPrds[k].price;
    });

    setSelectedPrds(selectedPrds);
    setTotalCartPrice(totalPrice);
    localStorage.removeItem('selectedPrds');
    localStorage.setItem('selectedPrds', JSON.stringify(selectedPrds));
    localStorage.removeItem('totalCartPrice');
    localStorage.setItem('totalCartPrice', totalPrice);
  }

  const handleProceedToSponsorship = (e) => {
    if (totalCartPrice <= 0) {
      setModalLive(true);
    } else {
      history("/sponsorship");
    }
    e.preventDefault();
  }

  const handleForceProceedToSponsorship = (e) => {
    setModalLive(false);
    history("/sponsorship");
  }

  const handleModalClose = () => {
    setModalLarge(false);
  }

  const handleViewMembership = () => {
    setModalLarge(true);
  }

  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <LandingPageHeader />
        <div className="section">
          <Container>
            <Row className="text-center">
              <Col className="text-center" md="12">
                <h2 className="title">Choose your Membership / Renewal Option</h2>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <blockquote className="blockquote blockquote-primary">
                      <span>
                      Family of three and four members gets 5% and 10% benefit respectively on Utsav Membership.
                      </span>
                      <br />
                      <span>
                      <b>Experience exclusivity!</b> Utsav membership for upto 4 family members gets waived off at checkout on full pujo sponsorship exceeding INR 15,000. 
                      </span>
                      <br/>
                      <span>Please select <b>your family member count</b> while selecting Membership/Renewal option.</span>
                      <br />
                      <span>
                        <Button className="btn-round" size="sm" color="danger" onClick={() => handleViewMembership()}>
                          <i className="now-ui-icons gestures_tap-01"></i>
                          Membership plan Comparison
                        </Button>
                      </span>
                    </blockquote>
                  </CardBody>
                </Card></Col>
            </Row>
            <Row>
              {membershipProducts != null && membershipProducts.map((mprd) => (
                mprd.available &&
                <Col md="4">
                  <Card border="primary" className="nav-tabs text-center" outline="true">
                    <CardImg
                      alt="..."
                      src={require("assets/img/bg23.png")}
                      top
                    ></CardImg>
                    <CardBody>
                      <CardTitle tag="h4">{mprd.name}</CardTitle>
                      <CardText>
                        <h5>&#x20B9;{mprd.price}/person</h5>
                      </CardText>
                      <CardText>
                        <Badge
                          size="sm"
                          className="btn-round"
                          color="primary"
                          onClick={() => { handleProductDec(mprd.id, mprd.price, mprd.code) }}
                        >
                          <b><i className="now-ui-icons ui-1_simple-delete"></i></b>
                        </Badge>
                        <span> &nbsp; {selectedPrds != null && selectedPrds[mprd.id] != null ? selectedPrds[mprd.id].qty : 0} &nbsp;</span>
                        <Badge
                          size="sm"
                          className="btn-round"
                          color="primary"
                          onClick={() => { handleProductInc(mprd.id, mprd.price, mprd.code) }}
                        >
                          <i className="now-ui-icons ui-1_simple-add"></i>
                        </Badge>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              ))}

            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardBody>
                    <Row>
                      <Col md="8" className="text-left">
                        <CardText><h5><b>Total Amount: &#x20B9;{totalCartPrice}</b></h5></CardText>
                      </Col>
                      <Col md="4" className="text-right">
                        <Button color="primary" onClick={handleProceedToSponsorship}><b>Proceed for Sponsorship</b></Button>
                      </Col>
                    </Row>


                  </CardBody>
                </Card>
              </Col>

            </Row>

            <Modal
              isOpen={modalLarge}
              color="black"
              className="modal-lg"
              modalClassName="bd-example-modal-lg"
              toggle={() => setModalLarge(false)}
            >
              <ModalHeader data-background-color="black">
                <h5 className="modal-title" id="myLargeModalLabel">
                  <b>Detailed Comparison</b>
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={() => handleModalClose()}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </ModalHeader >
              <ModalBody className="text-center" data-background-color="black">

                <Row>
                  <Col>
                    <div>
                      <Table className="table-pricing text-left" responsive>
                        <thead class="text-primary text-white">
                          <tr>
                            <th>

                            </th>
                            <th class="text-center">
                              <h6 class="card-title"><b>AASHIRBAD</b></h6>
                            </th>
                            <th class="text-center">
                              <h6 class="card-title"><b>HOI HULLOR</b></h6>
                            </th>
                            <th class="text-center">
                              <h6 class="card-title"><b>UTSAV</b></h6>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="bg-primary">
                            <td class="text-white" colSpan={4}><b>Membership Contribution (per Person)</b></td>
                          </tr>
                          <tr class="text-white">
                            <td>

                              <b>Contribution</b> (<b>UTSAV</b> contribution will be lower for family of three or more members)
                            </td>
                            <td class="text-center">
                              <b>&#x20B9;1250</b>
                            </td>
                            <td class="text-center">
                              <b>&#x20B9;2000</b>
                            </td>
                            <td class="text-center">
                              <b>&#x20B9;2400</b>
                            </td>
                          </tr>
                          <tr class="bg-primary">
                            <td class="text-white"><b>Features</b></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr class="text-white">
                            <td>Member dedicated queue for Anjali, Bhog & Maa Boron (*)</td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td>Parking (on availability)</td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td>Bhog - Saptami</td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td>Bhog - Ashtami & Nabami</td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td>Dandiya Participation</td>
                            <td class="text-center">
                              <span></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td>Cultural Program - External</td>
                            <td class="text-center">
                              <span></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td>Participation in in-house cultural program</td>
                            <td class="text-center">
                              <span></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td>Gala Dinner - Bijoya Dashami (**)</td>
                            <td class="text-center">
                              <span></span>
                            </td>
                            <td class="text-center">
                              <span></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>
                          <tr class="text-white">
                            <td>Bhog in Laxmi, Kaali & Saraswati Puja (**)</td>
                            <td class="text-center">
                              <span></span>
                            </td>
                            <td class="text-center">
                              <span></span>
                            </td>
                            <td class="text-center">
                              <span class="badge-circle badge badge-success"><i></i></span>
                            </td>
                          </tr>

                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
            <Modal toggle={() => setModalLive(false)} isOpen={modalLive}>
              <div className="modal-header">
                <h5 className="modal-title" id="membershipWarnModal">
                  Are you sure to proceed?
                </h5>
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={() => setModalLive(false)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                <p><b>You havn't added your family member count.</b> Are you sure to proceed for sponsorship?</p>
              </div>
              <div className="modal-footer">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => setModalLive(false)}
                >
                  No, Stay Here
                </Button>

                <Button
                  color="warning"
                  type="button"
                  onClick={(e) => handleForceProceedToSponsorship(e)}
                >
                  Yes, Proceed
                </Button>

              </div>
            </Modal>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default MembersipPage;
